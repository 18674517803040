import { observable, computed, action } from "mobx";
import axios from 'axios'
import {BACKEND_URL} from "../utils/vars";

export default class UserStore {
    constructor(rootStore) {
        this.rootStore = rootStore
    }
    @observable STATUSES = {
        SENDING: 0,
        ERROR: 1,
        FINISHED: 2,
        DEFAULT: 3,
        FETCHING_USER: 4,
    }
    @observable user = null;
    @observable status = this.STATUSES.DEFAULT;
    @observable error = ''
    @observable openPhone = false
    @action
    async getMe() {
        let token = localStorage.getItem('token')
        this.status = this.STATUSES.FETCHING_USER
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/auth/me/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.user = res.data.user
                resolve(res.data.user)
                this.status = this.STATUSES.FINISHED
            }).catch((e) => {
                this.status = this.STATUSES.ERROR
                if (e.response && e.response.status === 401) {
                    this.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action setNewPassword(token, password){
        return new Promise((resolve, reject) => {
            this.status = this.STATUSES.SENDING
            this.error = ''
            axios.post(`${BACKEND_URL}/auth/set_new_password/`, {
                password: password,
                token: token
            }).then((res) => {
                this.status = this.STATUSES.FINISHED
                resolve(true)
            }).catch((e) => {
                reject(false)
                if (e.response !== undefined) {
                    this.status = this.STATUSES.ERROR
                    if (e.response.status === 403) {

                    }
                    else if (e.response.status === 404) {
                        this.status = this.STATUSES.ERROR
                        this.error = 'Пользователь не найден'
                    }
                    else if (e.response.status === 401) {
                        this.logout()
                    }
                }
                resolve(false)
            })

        });
    }
    @action checkPasswordToken(token){

        return new Promise((resolve, reject) => {
            this.status = this.STATUSES.SENDING
            this.error = ''
            axios.post(`${BACKEND_URL}/auth/check_forgot_password_token/`, {
                token: token
            }).then((res) => {
                this.status = this.STATUSES.FINISHED
                resolve(true)
            }).catch((e) => {
                reject(false)
                if (e.response !== undefined) {
                    console.log(e.response)
                    this.status = this.STATUSES.ERROR
                    if (e.response.status === 403) {
                        this.status = this.STATUSES.ERROR
                    }
                    else if (e.response.status === 404) {
                        this.status = this.STATUSES.ERROR
                        this.error = 'Пользователь не найден'
                    }
                    else if (e.response.status === 401) {
                        this.logout()
                    }
                }
                resolve(false)
            })

        });
    }
    @action generatePassword(email){

        return new Promise((resolve, reject) => {
            this.status = this.STATUSES.SENDING
            this.error = ''
            axios.post(`${BACKEND_URL}/auth/generate_forgot_password/`, {
                email: email
            }).then((res) => {
                this.status = this.STATUSES.FINISHED
                resolve(true)
            }).catch((e) => {
                console.log(e)
                console.log(e.response)
                if (e.response !== undefined) {
                    this.status = this.STATUSES.ERROR
                    if (e.response.status === 403) {
                        this.status = this.STATUSES.ERROR
                    }
                    else if (e.response.status === 404) {
                        this.status = this.STATUSES.ERROR
                        this.error = 'Пользователь не найден'
                        reject('Пользователь не найден')
                    }
                    else if (e.response.status === 401) {
                        this.logout()
                    }
                }
                // resolve(false)
            })

        });
    }
    @action
    async register(userData) {
        this.status = this.STATUSES.SENDING
        this.error = ''
        return new Promise((resolve, reject) => {

            axios.post(`${BACKEND_URL}/auth/`, userData).then((res) => {
                this.status = this.STATUSES.FINISHED
                this.user = res.data.user;
                // localStorage.token = res.data.token
                axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
                resolve(true)
            }).catch((e) => {
                console.log(e)
                console.log(e.response)
                this.status = this.STATUSES.ERROR
                if (e.response !== undefined) {
                    if (e.response.status === 403) {
                        this.status = this.STATUSES.ERROR
                    }
                    else if (e.response.status === 400) {
                        if ("email" in e.response.data) {
                            if (e.response.data.email[0] === "Пользователь с таким Электронная почта уже существует.") {
                                this.error = "Пользователь с таким адресом уже существует"
                                reject("Пользователь с таким адресом уже существует")
                            }
                        }
                        this.status = this.STATUSES.ERROR
                    }
                    else if (e.response.status === 401) {
                        this.logout()
                    }
                }
                reject("Ошибки на сервере")
            })

        });
    }
    @action updateUser(userData){
        this.status = this.STATUSES.SENDING
        this.error = ''
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {

            axios.patch(`${BACKEND_URL}/auth/${this.user.id}/`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.status = this.STATUSES.FINISHED
                this.user = res.data;
                console.log(res)
                resolve(true)
            }).catch((e) => {
                console.log(e)
                console.log(e.response)
                this.status = this.STATUSES.ERROR
                if (e.response !== undefined) {
                    if (e.response.status === 403) {
                        this.status = this.STATUSES.ERROR
                    }
                    else if (e.response.status === 400) {
                        this.status = this.STATUSES.ERROR
                    }
                    else if (e.response.status === 401) {
                        this.logout()
                    }
                }
                resolve(false)
            })

        });
    }
    @action
    async login(username, password) {
        this.status = this.STATUSES.SENDING
        this.error = ''
        return new Promise((resolve, reject) => {

            axios.post(`${BACKEND_URL}/auth/login/`, {
                username: username,
                password: password
            }).then((res) => {
                this.status = this.STATUSES.FINISHED
                this.user = res.data.user;
                console.log('user', res.data.user)

                localStorage.setItem('token', this.user.token)
                axios.defaults.headers.common.Authorization = `Bearer ${this.user.token}`;
                resolve(true)
            }).catch((e) => {
                console.log(e.response)
                this.status = this.STATUSES.ERROR
                if(e.response!==undefined){
                    if (e.response.status === 403) {
                        this.error = "Проверьте правильность написания логина и пароля";
                        this.status = this.STATUSES.ERROR
                    }
                    else if (e.response.status === 401) {
                        this.logout()
                    }
                }
                resolve(false)
            })

        });
    }
    @action logout() {
        localStorage.removeItem('token')
        axios.defaults.headers.common.Authorization = null
        this.user = null
    }
}
