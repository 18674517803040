import React from "react";

const ru = {
    //LOGIN
    language: 'Русский',
    login: 'Войти',
    register: 'Регистрация',
    have_no_account: 'У вас ещё нет аккаунта?',
    forgot_password: 'Забыли пароль?',
    password: "Пароль",
    courses: {
        title: 'Курсы',
        card: 'предмет'
    },
    exam: {
        attention: 'Внимание',
        testAbortText: 'Вы действительно хотите прервать тест? Так как таймер уже запущен нужно пройти тест полностью',
        abort: 'Прервать',
        continue: 'Продолжить',
        user: 'Уважаемый пользователь',
        attemptsLimit: 'Вы превысили количество попыток в тесте либо же у вас нету доступа к тесту',
        goBack: 'Обратно'
    },
    tests: {
        title: 'Тесты',
        missed_answers: 'Вы ответили не на все вопросы',
        timing: 'Продолжительность',
        questionsCount: 'Кол-во вопросов',
        attempts: 'Осталось попыток',
        start: 'Начать тест',
        result: 'Посмотреть результаты',
        bug_work_video: 'Видео решение задач'
    },
    sundayTests: {
        title: 'Воскресные тесты',
    },
    books: {
        title: 'Книги',
        more: 'Подробнее'
    },
    subscriptions: {
        title: 'Подписки'
    },
    lesson: {
        videoLesson: 'Видеоурок',
        task: 'Задание',
        lessons: 'Уроки',
        showCorrect: 'Посмотреть ответы',
        nextLesson: 'Перейти на следующий урок',
        retry: 'Сдать заново',
        submit: 'Сдать',
        result: 'Ваш результат',
        resultFrom: 'баллов из',
        grats: 'Поздравляем'
    },
    nav: {
        home: 'Главная',
        course: 'Курсы',
        test: 'Тесты',
        book: 'Книги',
        subscription: 'Подписки',
        faq: 'FAQ',
        leaderboard: 'Лидерборд',
        sundayTests: 'Воскресные тесты'
    },
    profile: 'Личный кабинет',
    logout: 'Выход',
    settings: {
        title: 'Личный кабинет',
        parent_name: 'ФИО Родителя',
        name: 'ФИО Ученика',
        phone: 'Телефон',
        grade: 'Класс',
        email: 'Эл. адрес',
        language: 'Язык обучения',
        city: 'Город',
        password: 'Пароль',
        cancel: 'Отменить',
        save: 'Сохранить',
        change: 'Изменить'
    },
    auth: {
        errors: {
            first_name: 'Введите полное ФИО',
            email: 'Введите корректный E-mail',
            password: 'Пароль должен быть минимум 6 символов',
            confirmPassword: 'Пароли не совпадают',
            phone: 'Укажите правильно номер телефона',
            grade: 'Укажите класс',
            login: 'Не верный логин или пароль'
        }
    },
    kaspi: {
        app: 'Скачайте Мобильное приложение Kaspi.kz в AppStore или Google Play',
        pays: 'Откройте раздел "Платежи" и найдите "Bolashak School (г.Алматы, ул. Сатпаева, 22Б)"',
        qr: 'Также вы можете отсканировать “Kaspi QR”',
        fio: 'Введите ФИО учащегося',
        sum: 'Введите сумму к оплате вручную и нажмите "Оплатить"',
        final: 'После оплаты отправьте чек на номер WhatsApp 87752977109',
        send: 'Отправить чек'
    }
};

export default ru;
