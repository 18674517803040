import React, {useEffect, useState} from 'react'
import Popup from 'reactjs-popup';
import './modal.scss'

export default function Timer({seconds, onFinish, onSecondPass, initialValue, finish=false}){

    const [timeLeft, setTimeLeft] = useState(seconds);
    const [time, setTime] = useState({h:0, m:0, s:0})
    const [modalOpen, setModalOpen] = useState(false)
    const [firstTimeout, setFirstTimeout] = useState(false)
    const [secondTimeout, setSecondTimeout] = useState(false)

    const secondsToTime = (secs) =>{
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    useEffect(()=>{
        setTimeLeft(seconds);
    }, [seconds])
    useEffect(() => {
        // exit early when we reach 0

        if (timeLeft===0 || finish) {
            setTimeLeft(0);
            let s = secondsToTime(0)
            setTime(s)
            onFinish()
        }
        else if(timeLeft===0 && !finish){
            setTimeLeft(seconds)
            let s = secondsToTime(seconds)
            setTime(s)
        }
        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
            let s = secondsToTime(timeLeft)
            setTime(s)

            if (s.m == '20' && s.s == '0') {
                setSecondTimeout(false)
                setFirstTimeout(true)
                setModalOpen(true)
            }

            if (s.m == '5' && s.s == '0') {
                setFirstTimeout(false)
                setSecondTimeout(true)
                setModalOpen(true)
            }
            // onSecondPass();
        }, 1000);


        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [timeLeft]);

    return (
        <>
            <span id="my_timer">{time.h}:{time.m}:{time.s}</span>
            <Popup open={modalOpen} closeOnDocumentClick onClose={()=>{
                setModalOpen(false)
            }}>
                <div className="modal">
                    <a className="close" onClick={()=>{
                        setModalOpen(false)
                    }}>
                        &times;
                    </a>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <h2>У вас осталось {firstTimeout ? 20 : secondTimeout ? 5 : ''} минут</h2>
                        <button 
                            onClick={()=>setModalOpen(false)} 
                            className="modal__btn">
                                Хорошо
                        </button>
                    </div>
                </div>
            </Popup>
        </>
    )
}
