import React from 'react';
import './subscript.sass';

import ModuleCard from "../common/cards/ModuleCard";
import testsImg from './images/tests.svg'
import ModuleImg from './images/modules.svg'
import {withTranslation} from "react-i18next";

@withTranslation()
class Subscription extends React.Component {


    render() {
        let {t} = this.props;
        return (
            <div className="subscript">
                <div className="subscript__inner">
                    <div className="subscript__suptitle">{t('subscriptions.title')} </div>
                    <div className="subscript__items">
                    {/*<ModuleCard image={ModuleImg} name={'Курсы'} onClick={()=>{*/}
                    {/*    // this.props.history.push('/subscribe/modules')*/}
                    {/*}}/>*/}
                    <ModuleCard image={testsImg} name={'Тесты'} onClick={()=>{
                        this.props.history.push('/subscribe/tests')
                    }}/>
                    </div>
                </div>
            </div>
        )
    }


}

export default Subscription;
