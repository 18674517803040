import React, {useEffect, useState} from 'react';
import './App.sass';
import {observer, Provider} from 'mobx-react'
import stores from './stores'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, Redirect
} from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Course from "./components/course/course";
import Authorization from "./components/authorization/authorization";
import TopBar from "./components/navbar/topbar";
import CourseDetailed from "./components/lesson/CourseDetailed";
import ModuleDetailed from "./components/lesson/ModuleDetailed";
import LessonDetailed from "./components/lesson/LessonDetailed";
import Exam from "./components/tests/exam";
import Tests from "./components/tests/tests";
import TestGroups from "./components/tests/TestGroups";
import TestLessons from "./components/tests/TestLessons";
import Books from "./components/books/books";
import BookDetail from "./components/books/BookDetail";
import Setting from "./components/authorization/Setting";
import RestorePassword from "./components/authorization/restorePassword";
import { useHistory } from "react-router-dom";
import Faq from "./components/faq/faq";
import Module from "./components/subscription/Module";
import CreateNewPassword from "./components/authorization/CreateNewPassword";
import Subscription from "./components/subscription/Subscription";
import SubscribeTest from './components/subscription/Tests'
import SpinnerCustom from "./components/common/SpinnerCustom";
import Leaderboard from './components/leaderboard';
import {toJS} from "mobx";
import {withTranslation} from "react-i18next";
import {LANGUAGES_SMALL} from "./utils/vars";
import ExamRedirector from "./components/tests/examRedirector";
import ExamHistory from "./components/tests/exam-history";
import SundayTests from "./components/sunday-tests/SundayTests";
import Home from './components/home/Home';

function Dashboard({navigation}){
    let history = useHistory()
    const [showNavbar, setShowNavbar] = useState(false);
    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера
        // let token = !!localStorage.getItem('token')
        // if(!token) history.replace('/login')
    }, []);

    return (
        <>
            <Navbar toggle={showNavbar} onClick={()=>setShowNavbar(false)}/>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflow: "scroll",
            WebkitOverflowScrolling: "touch"}}>
            <TopBar active={showNavbar} onClickBurger={()=>setShowNavbar(!showNavbar)}/>
            <div className={"custom-container" + (showNavbar ? " custom-container-inactive": "")}>
                <a href="https://wtsp.cc/77752977109"
                   className="float" target="_blank">
                    <i className="fa fa-whatsapp my-float"></i>
                </a>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path={"/course/:id"} component={CourseDetailed}/>
                    <Route path={"/module/:id"} component={ModuleDetailed}/>
                    <Route
                        path="/lesson/:categoryId?/:id?"
                        render={props => {
                            const {
                                match: {
                                    params: { categoryId, id }
                                }
                            } = props;
                            return (
                                <LessonDetailed
                                    key={`category=${categoryId}&id=${id}`}
                                    {...props}
                                />
                            );
                        }}
                    />;
                    <Route path={"/test/"} exact component={Tests}/>
                    <Route path={"/test/:id/"} exact component={TestGroups}/>
                    <Route path={"/test/:categoryId/:id/"} exact component={TestLessons}/>
                    <Route path={"/test/:categoryId/:lessonId/exam/:id/"} component={Exam}/>
                    <Route path={"/test/:categoryId/:lessonId/exam-redirect/:id/"} component={ExamRedirector}/>
                    <Route path={"/test/:categoryId/:lessonId/exam-history/:testId/:id/"} component={ExamHistory}/>
                    <Route path={"/sunday-tests/"} exact component={SundayTests}/>
                    <Route path={"/books/"} exact component={Books}/>
                    <Route path={"/books/:id"} exact component={BookDetail}/>
                    <Route path={"/subscribe"} exact component={Subscription}/>
                    <Route path={"/subscribe/modules"} exact component={Module}/>
                    <Route path={"/subscribe/modules/:order_id"} exact component={Module}/>
                    <Route path={"/subscribe/tests"} exact component={SubscribeTest}/>
                    <Route path={"/subscribe/tests/:order_id"} exact component={SubscribeTest}/>
                    <Route path={"/settings"} exact component={Setting}/>
                    <Route path={"/faq"} exact component={Faq}/>
                    <Route path={"/courses"} exact component={Course}/>
                    <Route path={"/leaderboard/:id?"} exact component={Leaderboard} />
                </Switch>
            </div>
            </div>
        </>
    )
}
function LoginRouter() {
    return(
        <>
            <Route path={'/'} exact component={Authorization}/>
            <Route path={'/verify/:token'} exact component={Authorization}/>
            <Route path={'/restore'} exact component={RestorePassword}/>
            <Route path={'/restore/:token'} exact component={CreateNewPassword}/>
        </>
    )
}
@withTranslation()
@observer
class App extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            loader: true
        }
    }
    // state: {
    //     loader: true
    // }
    // async componentDidMount() {
    //     try{
    //         let user = await stores.userStore.getMe()
    //         await this.props.i18n.changeLanguage(LANGUAGES_SMALL[user.language])
    //         if(this.state.loader) this.setState({loader: false})

    //     }
    //     catch (e) {

    //     }
    // }

    componentDidMount() {
        try{
            stores.userStore.getMe().
                then(res => {
                    this.props.i18n.changeLanguage(LANGUAGES_SMALL[res.language])
                    this.setState({ loader: false })
                })
        }
        catch (e) {

        }
    }

    render() {
        let {i18n} = this.props;
        let {user,status, STATUSES} = stores.userStore
        user = toJS(user)

          return (
              <Router>
                  <Provider store={stores}>
                      <div className="App">
                          <Switch>
                              {!!localStorage.token ? <Dashboard /> : <LoginRouter/> }
                          </Switch>
                      </div>
                  </Provider>
              </Router>
          )
  }

}

export default App;
