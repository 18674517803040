import React, {useState} from 'react';
import './exam.sass';
import Navbar from '../navbar/navbar.js'
import arrow from '../images/arrowLessons.svg';
import timer from '../images/clock.svg'
import MarkdownRender from "../common/MarkdownRenderer";
import LessonTask, {ExamItem} from "../lesson/LessonTask";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import Timer from "./timer";
import {LANGUAGES_SMALL} from "../../utils/vars";
import * as PropTypes from "prop-types";

@inject('store') @observer
class ExamHistory extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            answers: [],
        }
    }
    componentDidMount = async () => {
        let id = this.props.match.params['id']
        let testId = this.props.match.params['testId']
        if (id){
            await this.props.store.testStore.getTestsHistory(id);
        }
    };

    render() {
        let {user} = this.props.store.userStore;
        let {testHistory} = this.props.store.testStore
        let {categoryId,lessonId, id} = this.props.match.params
        testHistory = toJS(testHistory);
        let lang = LANGUAGES_SMALL[2]
        if (user) {
            lang = LANGUAGES_SMALL[user.language]
        }
        let answers = []
        if (testHistory) {
            answers = JSON.parse(testHistory.answers);
        }
        let counter = 0;
        return (
            <div className="exam">
                <div className="exam__inner history">
                    <div className="exam__wrapper">
                        {testHistory && (
                            <div className="exam__title"> <img src={arrow} alt="" onClick={()=>{
                                window.location.href = `/test/${categoryId}/${lessonId}`;
                                this.props.store.testStore.cleanTest();
                            }} />{testHistory.category[`name_${lang}`]}</div>
                        )}
                    </div>
                    {testHistory && testHistory.category.tests.map((task, index)=>{
                        let {ans_a, ans_b, ans_c, ans_d, ans_e, description} = task
                        if (lang){
                            if(task[`ans_a_${lang}`] && task[`ans_a_${lang}`]!==''){
                                ans_a =task[`ans_a_${lang}`]
                            }
                            if(task[`ans_b_${lang}`] && task[`ans_b_${lang}`]!==''){
                                ans_b =task[`ans_b_${lang}`]
                            }
                            if(task[`ans_c_${lang}`] && task[`ans_c_${lang}`]!==''){
                                ans_c =task[`ans_c_${lang}`]
                            }
                            if(task[`ans_d_${lang}`] && task[`ans_d_${lang}`]!==''){
                                ans_d =task[`ans_d_${lang}`]
                            }
                            if(task[`ans_e_${lang}`] && task[`ans_e_${lang}`]!==''){
                                ans_d =task[`ans_d_${lang}`]
                            }
                            if(task[`description_${lang}`] && task[`description_${lang}`]!==''){
                                description =task[`description_${lang}`]
                            }
                        }
                            if(task.type!=='empty') counter=counter+1;
                            return(
                                <ExamItem
                                    title={description}
                                    empty={task.type==='empty'}
                                    key={index}
                                    index={counter}
                                    a={ans_a}
                                    b={ans_b}
                                    c={ans_c}
                                    d={ans_d}
                                    e={ans_e}
                                    id={task.id}
                                    edit={false}
                                    correct={task.correct}
                                    userClicked={answers[task.id]}
                                />
                            )
                        return null;
                    })}
                </div>
            </div>
        );
    }


};


export default ExamHistory;
