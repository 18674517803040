import React from 'react';
import './lessons.sass';
import Navbar from '../navbar/navbar.js';
import Lesson from './lesson.js'
import arrowLessons from '../images/arrowLessons.svg';
import lessonscard1 from '../images/lessonsCard1.svg';
import lessonscard2 from '../images/lessonCard2.svg';
import lessonscard3 from '../images/lessonsCard3.svg';
import lessonscard4 from '../images/lessonCard4.svg';
import {inject, observer} from "mobx-react";
import { withRouter } from "react-router-dom";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import ModuleCard from "../common/cards/ModuleCard";

@inject('store') @observer
class CourseDetailed extends React.Component{
    async componentDidMount() {
        try{

            if(!this.props.match.params['id']){
                this.props.history.push('/')
            }
            let id = this.props.match.params['id']
            await this.props.store.courseStore.selectCourse(id)
        }
        catch (e) {

        }
    }

    render() {
        let {selectedCourse} = this.props.store.courseStore
        selectedCourse = toJS(selectedCourse)
        console.log(selectedCourse)
        if(selectedCourse===null) return (
            <>
            <SpinnerCustom/>
            </>
        )
        return (
            <div className="lessons">
                {/*<Lesson></Lesson>*/}
                {/* ========= Курсы--> =====fn Lessons-сабактар ================Lesson-ишиндеги модульдар=========================== */}

                { <div className="lessons__inner">
                    <div className="lessons__subtitle">

                        <img src={arrowLessons} alt="" onClick={()=>{
                            this.props.history.push('/')
                        }}/>{selectedCourse.name}</div>
                    <div className="lessons__description">{selectedCourse.description}</div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {selectedCourse.modules.map(item=>{

                            return(
                                <ModuleCard image={item.image} name={item.name} onClick={()=>{
                                    this.props.history.push(`/module/${item.id}`)
                                }} done={!item.closed}/>
                            )
                        })}
                    </div>
                </div> }

            </div>
        )
    }


}

export default withRouter(CourseDetailed);
