import React from 'react';
import './subscript.sass';
import Navbar from '../navbar/navbar.js';
import check from '../images/check.svg'
import tenge from '../images/cost.svg'
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import ModuleSubscribeCard from "./ModuleSubsribeCard";
import course from "../course/course";
import CryptoJs from 'crypto-js'
import arrow from "../images/arrowLessons.svg";
@inject('store') @observer
class Module extends React.Component {
    state={
        selectedCourse: null
    }
    async componentDidMount() {

        try {
            if ('order_id' in this.props.match.params){
                let order_id = this.props.match.params['order_id']
                await this.props.store.courseStore.verifyModuleSubscribe(order_id)
                this.props.history.replace('/subscribe/modules')
            }
            let coursePrice = await this.props.store.courseStore.getCoursePrice()

            if(coursePrice){
                this.setState({selectedCourse: 0})
            }
        } catch (e) {

        }
    }

    async subscribe(module){
        try {
            const subscribe =
                await this.props.store.courseStore.addModuleSubscribe(module.id)
            const salt = Math.random().toString(36).substring(7);
            // console.log('id', subscribe.id)
            let datas = {
                pg_merchant_id: 515533,
                pg_order_id: subscribe.id,
                pg_amount: module.price,
                pg_description: `Покупка на Bolashak School ${module.name}`,
                pg_salt: salt,
                pg_testing_mode: 0,
                pg_result_url: `http://178.170.221.165:8000/api/price/verify/`,
                pg_success_url: `http://178.170.221.165:3000/subscribe/modules/${subscribe.id}`,
            }
            let hashString = '';
            let urlString = "https://api.paybox.money/payment.php?"
            const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})
            datas = sortObject(datas)
            for (let data in datas) {
                hashString += datas[data] + ';'
                urlString += `${data}=${datas[data]}&`
            }
            hashString = 'payment.php;' + hashString + 'WSQB7H64BLpBORzX';
            hashString = CryptoJs.MD5(hashString).toString()
            urlString += `pg_sig=${hashString}`
            // console.log(subscribe.id)
            window.open(urlString, "_self")
        }
        catch (e) {

        }
    }
    render() {
        let {selectedCourse} = this.state;
        let {coursesPrices} = this.props.store.courseStore;
        coursesPrices = toJS(coursesPrices)
        let modules = null
        if(coursesPrices) {
            if (selectedCourse || selectedCourse===0) {
                modules = coursesPrices[selectedCourse].modules
            }
        }
        return (
            <div className="subscript">
                <div className="subscript__inner">
                    <div className="subscript__suptitle">  <img src={arrow} alt="" onClick={()=>{
                        this.props.history.push('/subscribe')
                    }} /> Подписки </div>
                    <div className="subscript__directions">
                        {coursesPrices && coursesPrices.map((course, index)=>{
                            return (
                                <button className={"subscript__direction" +
                                ((selectedCourse===index) ? " subscript__direction-click" : "")} onClick={()=>{
                                    this.setState({selectedCourse: index})
                                }} key={index}>
                                    {course.name}
                                </button>
                            )
                        })}
                    </div>
                    <div className="subscript__items">
                        {modules && modules.map((module)=>{
                            return (
                                <ModuleSubscribeCard name={module.name}
                                                     description={module.description}
                                                     price={module.price}
                                                     categories={module.categories}
                                                     onClick={()=>this.subscribe(module)}
                                />
                            )
                        })}


                    </div>
                </div>
            </div>
        )
    }


}

export default Module;
