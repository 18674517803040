import {ClipLoader} from "react-spinners";
import React from "react";
export default function SpinnerCustom(){
    return(
        <div style={{
            position: 'absolute',
            top: 0,
            bottom:0,
            right:0,
            left: 0,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <ClipLoader size={90}>
                {/*<span className="sr-only">Загрузка...</span>*/}
            </ClipLoader>
        </div>
    )
}
