import React from 'react';
import './tests.sass';
import MainCard from '../common/cards/mainCard'
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import arrowLessons from "../images/arrowLessons.svg";

import { withTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './modal.scss'
import MarkdownRender from "../common/MarkdownRenderer";
@inject('store') @observer
class Tests extends React.Component{
    state = {
        open: false
    }
    async componentDidMount() {
        let id = this.props.match.params['id']
        if (id){
            await this.props.store.testStore.setSelectedCategory(id)
            console.log('id', id)
        }
    }
    closedPopup(){

    }
    render() {
        let {selectedCategory, STATUSES, status} = this.props.store.testStore
        selectedCategory = toJS(selectedCategory)
        console.log(selectedCategory)
        if(selectedCategory===null || selectedCategory===undefined) return (
            <SpinnerCustom/>
        )

        return (
            <div className="tests">
                <div className="tests__inner">
                    <div className="tests__suptitle"><img src={arrowLessons} alt="" onClick={()=>{
                        this.props.history.push(`/test/`)
                    }}/> {selectedCategory['name_' + this.props.i18n.language]} </div>
                    <div className="tests__description" ><MarkdownRender source={selectedCategory['description_' + this.props.i18n.language]}/> </div>
                    <div className="tests__group__items">
                        {selectedCategory.test_groups.map((group)=>{

                            return <div className={"tests__group_item"} onClick={()=>{
                                if(!('closed' in group)) this.props.history.push(`/test/${selectedCategory.id}/${group.id}/`)
                                else this.setState({open:true})
                            }}>
                                <p className={"tests__group_item__title "+
                                ('closed' in group ? "" : " tests__group_item__title-active")}
                                >{group.name}  {!('closed' in group) ? <img src={require('../images/arrow.svg')} alt="Go to test"/> :
                                    <img src={require('../images/lock.svg')} alt="Test locked"/>}</p>

                            </div>

                        })}


                    </div>
                    <Popup open={this.state.open} closeOnDocumentClick onClose={()=>{
                        this.setState({open:false})
                    }}>
                        <div className="modal">
                            <a className="close" onClick={()=>{
                                this.setState({open:false})
                            }}>
                                &times;
                            </a>

                            <h2>Уважаемый пользователь</h2>
                            <p>Чтобы пройти этот вариант необходимо купить подписку</p>
                            <button onClick={()=>this.props.history.push('/subscribe/tests')} className="modal__btn">Подписка</button>
                        </div>
                    </Popup>
                </div>

            </div>
        )
    }


}

export default withTranslation()(Tests);
