import React from "react";

export default function Burger({isActive, onClick}){
    let classText = "hamburger hamburger--squeeze";
    if(isActive) classText  = classText + " is-active"
    return(
        <button className={classText} type="button" onClick={onClick}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
        </button>
    )
}
