import React, { useEffect, useState } from 'react';
import './navbar.sass';
import logo from './images/logo-white.png';
import {NavLink} from "react-router-dom";
import link1 from './images/link-1.svg';
import link2 from './images/link-2.svg';
import link3 from './images/link-3.svg';
import link4 from './images/link-4.svg';
import link5 from './images/link-5.svg';
import link1Active from './images/link-1-active.svg';
import link2Active from './images/link-2-active.svg';
import link3Active from './images/link-3-active.svg';
import link4Active from './images/link-4-active.svg';
import link5Active from './images/link-5-active.svg';
import {useTranslation} from "react-i18next";
import stores from '../../stores'

function Navbar({toggle=false, onClick}) {
    let {t} = useTranslation();
    const [user, setUser] = useState();

    useEffect(async () => {
        let user = await stores.userStore.getMe();
        setUser(user);
    }, []);

    return (
        <div className={"navbar "+(toggle ? "navbar-active" : "")}>
            <NavLink className="navbar__logo" onClick={onClick} to={'/'}> <img src={logo} alt="" /> </NavLink>
            <ul className="navbar__menu">
                <NavLink 
                    onClick={onClick} to={'/'} 
                    isActive={(_, location) => location.pathname == '/'} 
                    activeClassName="navbar__link-active" className="navbar__link"
                >
                    <img className="icon_inactive" src={link1}/>
                    <img className="icon_active" src={link1Active}/>
                    {t('nav.home')}
                </NavLink>
                <NavLink onClick={onClick} to={'/courses'} isActive={(match, location) => {
                    if (location.pathname.includes('/subscribe/module')) {
                        return false;
                    }
                    if(location.pathname.includes('/course') || location.pathname.includes('/module') || location.pathname.includes('/lesson')){
                        return true
                    }
                    // const eventID = parseInt(match.params.eventID);
                    return false
                }} activeClassName="navbar__link-active" className="navbar__link">
                    <img className="icon_inactive" src={link3}/>
                    <img className="icon_active" src={link3Active}/>
                    {t('nav.course')}
                </NavLink>
                <NavLink onClick={onClick} to={'/test/'}  activeClassName="navbar__link-active" className="navbar__link">
                    <img className="icon_inactive" src={link2}/>
                    <img className="icon_active" src={link2Active}/>
                    {t('nav.test')}
                </NavLink>
                <NavLink onClick={onClick} to={'/books'}  activeClassName="navbar__link-active" className="navbar__link">
                    <img className="icon_inactive" src={link3}/>
                    <img className="icon_active" src={link3Active}/>
                    {t('nav.book')}
                </NavLink>

                <NavLink onClick={onClick} to={'/subscribe'}  activeClassName="navbar__link-active" className="navbar__link">
                    <img className="icon_inactive" src={link4}/>
                    <img className="icon_active" src={link4Active}/>
                    {t('nav.subscription')}
                </NavLink>
                <NavLink onClick={onClick} to={'/leaderboard'}  activeClassName="navbar__link-active" className="navbar__link">
                   <img className="icon_inactive" src={link4}/>
                   <img className="icon_active" src={link4Active}/>
                   {t('nav.leaderboard')}
                </NavLink>
                <NavLink onClick={onClick} to={'/faq'}  activeClassName="navbar__link-active" className="navbar__link">
                    <img className="icon_inactive" src={link5}/>
                    <img className="icon_active" src={link5Active}/>
                    {t('nav.faq')}
                </NavLink>
                {/* {user && user.sunday_test_allowed && (
                    <NavLink onClick={onClick} to={'/sunday-tests'}  activeClassName="navbar__link-active" className="navbar__link">
                        <img className="icon_inactive" src={link5}/>
                        <img className="icon_active" src={link5Active}/>
                        {t('nav.sunday_tests')}
                    </NavLink>
                )} */}
            </ul>
        </div>
    );
};

export default Navbar;
