import React from 'react'
import tenge from "../images/cost.svg";
import check from "../images/check.svg";

export default function ModuleSubscribeCard({name, price, description, categories, onClick}) {
    return (
        <div className="subscript__item subscript__item1">
            <div className="subscript__wrapper">
                <div className="subscript__up">
                    <div className="subscript__period subscript__period1">{name}</div>
                    <div className="subscript__cost">{price} <img src={tenge} alt="" /> </div>
                </div>
                <div className="subscript__descr">
                    {description}
                </div>
                <div className="subscript__box">
                    {categories && categories.map(category=>{
                        return (
                            <div className="subscript__lesson"> <img src={check} alt="" /> {category.name}</div>
                        )
                    })}
                    {/*<div className="subscript__lesson"> <img src={check} alt="" /> Английский</div>*/}
                    {/*<div className="subscript__lesson"> <img src={check} alt="" /> Русский язык</div>*/}
                    {/*<div className="subscript__lesson"> <img src={check} alt="" /> Казахский язык</div>*/}
                </div>
                <button className="subscript__btn" onClick={onClick}>Оформить подписку</button>
            </div>
        </div>
    )
}
