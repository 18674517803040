import React from 'react';
import './lessons.sass';
import arrowLessons from '../images/arrowLessons.svg';

import {inject, observer} from "mobx-react";
import { withRouter } from "react-router-dom";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import LessonsCollapse from "./LessonsCollapse";
import LessonTask from "./LessonTask";
import {LANGUAGES_SMALL} from "../../utils/vars";
import {withTranslation} from "react-i18next";


@withTranslation()
@inject('store') @observer
class LessonDetailed extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tab: 'video',

        }
        this.youtubeRef = React.createRef()
        this.youtubeContainerRef = React.createRef()
    }
    async componentDidMount() {
        console.log(this.props)
        if(!this.props.match.params['categoryId']){
            this.props.history.push('/')
        }
        let id = this.props.match.params['id']
        let categoryId = this.props.match.params['categoryId']
        let category = await this.props.store.courseStore.getCategory(categoryId)
        console.log(category)
        if (id){
            await this.props.store.courseStore.getLesson(id)
            this.props.store.courseStore.setHistory(id)
            console.log('id', id)
        }
        else{
            await this.props.store.courseStore.getLesson(category['lessons_list'][0].id)
        }

        if (this.youtubeRef.current) {
            const pl = new window.Plyr(this.youtubeRef.current)

            const divEl = document.createElement('div')
            divEl.style.position = 'absolute'
            divEl.style.width = '100%'
            divEl.style.height = '100%'
            divEl.style.left = 0
            divEl.style.top = 0

            pl.on('ready', () => {
                if (this.youtubeContainerRef.current && this.youtubeContainerRef.current.children.length) {
                    this.youtubeContainerRef.current.children[0].appendChild(divEl)
                }
            })

            divEl.addEventListener('click', () => {
                pl.play()
            }) 
        }
    }

    render() {
        let {tab} = this.state;
        let {selectedCategory, selectedLesson} = this.props.store.courseStore
        let {user} = this.props.store.userStore;
        let {t} = this.props;
        if (selectedCategory === null || selectedLesson === null) return (
            <SpinnerCustom/>
        )
        let lang = LANGUAGES_SMALL[user.language]
        selectedCategory = toJS(selectedCategory)
        selectedLesson = toJS(selectedLesson)
        let name, vimeo, youtube_video = null
        if(selectedLesson!==null) {
            name = selectedLesson.name
            vimeo = selectedLesson.vimeo
            if (`name_${lang}` in selectedLesson
                && selectedLesson[`name_${lang}`]
                && selectedLesson[`name_${lang}`]!=='') {
                name = selectedLesson[`name_${lang}`]
            }
            if (`vimeo_${lang}` in selectedLesson && selectedLesson[`vimeo_${lang}`] && selectedLesson[`vimeo_${lang}`]!=='') {
                vimeo = selectedLesson[`vimeo_${lang}`]
            }

            if (`youtube_${lang}` in selectedLesson && selectedLesson[`youtube_${lang}`] && selectedLesson[`yputube_${lang}`]!=='') {
                youtube_video = selectedLesson[`youtube_${lang}`]
            }
        }

        return (
            <div className="lessons">
                 <div className="lessons__inner">
                    <div className="lessons__subtitle">
                        <img src={arrowLessons} alt="" onClick={()=>{
                            this.props.history.push(`/module/${selectedCategory.module}`)
                        }}/>{selectedLesson && name}</div>
                     <div className="lesson_tab">
                         <div className={"lesson_tab__item "+(tab==='video' ? "active" : "")} onClick={()=>{
                             this.setState({tab: 'video'})
                         }}>
                             {t('lesson.videoLesson')}
                             <div className={"active_underline " + (tab==='video' ? "active" : "")}/>
                         </div>
                         <div className={"lesson_tab__item "+(tab==='task' ? "active" : "")}  onClick={()=>{
                             this.setState({tab: 'task'})
                         }}>
                             {t('lesson.task')}
                             <div className={"active_underline " + (tab==='task' ? "active" : "")}/>
                         </div>
                     </div>

                         {(tab==='video') ? (
                             <>
                             <div className="lesson_content">
                                 <div className="lesson_content__video" ref={this.youtubeContainerRef}>
                                    {youtube_video && (
                                        <div ref={this.youtubeRef} data-plyr-provider="youtube" data-plyr-embed-id={youtube_video}></div>
                                    )}
                                 </div>
                                 {/*<img src={require('./images/Rectangle 20.png')} className="lesson_content__video"/>*/}
                                 <LessonsCollapse
                                     lessons={selectedCategory && selectedCategory.lessons_list}
                                     language={lang}
                                     onClick={(lessonId)=>{
                                     this.props.history.replace(`/lesson/${selectedCategory.id}/${lessonId}`)
                                 }}/>
                             </div>

                             </>
                         ):(
                             <div className="lesson_content">
                                 <div className="exam">
                                     <div className="exam__items">
                                        <LessonTask
                                            lang={lang}
                                            tasks={selectedLesson.tasks}
                                            min={selectedLesson.task_min}
                                            onSubmit={(correct)=>{
                                            this.props.store.courseStore.setHistory(selectedLesson.id, correct)
                                            this.props.store.courseStore.getLesson(selectedLesson.id)
                                        }}  onPass={async () => {
                                            await this.props.courseStore.getCategory(selectedCategory.id)
                                            let nextLesson = this.props.store.courseStore.hasNextLesson
                                            if (nextLesson) {
                                                this.props.history.push(`/lesson/${selectedCategory.id}/${nextLesson.id}`)
                                            } else {
                                                this.props.history.push(`/subscribe/`)
                                            }
                                        }}
                                            onRestart={()=>{

                                            }}
                                        />
                                     </div>
                                 </div>
                             </div>
                         )
                         }


                </div>

            </div>
        )
    }


}

export default withRouter(LessonDetailed);
