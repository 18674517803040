import React, {useState} from 'react';
import './exam.sass';
import Navbar from '../navbar/navbar.js'
import arrow from '../images/arrowLessons.svg';
import timer from '../images/clock.svg'
import LessonTask from "../lesson/LessonTask";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import Timer from "./timer";
import {LANGUAGES_SMALL} from "../../utils/vars";
import { withTranslation } from 'react-i18next';
import { removeOutdatedTestData } from '../../utils/helpers';
import bgImg from './assets/img/LOGO.png'
import Popup from 'reactjs-popup';
import './modal.scss'

@inject('store') @observer
class Exam extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            answers: [],
            timeLeft: false,
            duration: 0,
            showHistory: false,
            modalOpen: false
        }
        this.timer = 0
    }
    componentDidMount = async () => {
        let id = this.props.match.params['id']
        removeOutdatedTestData();

        if (id){
            await this.props.store.testStore.getTests(id)

            let testCategories = toJS(this.props.store.testStore.testCategories)

            if (testCategories) {
                let startTime = null;
                let endTime = null;
                if(!localStorage.getItem(`exam_${id}_start`)){
                    startTime = localStorage.setItem(`exam_${id}_start`, new Date().toString());
                }
                else {
                    startTime = localStorage.getItem(`exam_${id}_start`)
                }

                if(!localStorage.getItem(`exam_${id}_end`)) {
                    endTime = localStorage.setItem(`exam_${id}_end`, new Date(new Date().getTime() + parseInt(testCategories.duration) * 60000));
                }
                else {
                    endTime = localStorage.getItem(`exam_${id}_end`);
                }

                if(startTime && endTime){
                    endTime = new Date(endTime);
                    const diffTime = Math.abs(endTime - new Date());
                    if(diffTime<0){
                        this.setState({
                            timeLeft: true
                        });
                    }
                    const diffMinutes = Math.ceil(diffTime / (1000));
                    this.setState({
                        duration: diffMinutes
                    })
                }
            }
        }
    };

    handleBack() {
        this.setState({modalOpen: true})
    }

    render() {
        let {user} = this.props.store.userStore;
        let {testCategories,testGroupAccess, STATUSES, status} = this.props.store.testStore
        let {categoryId,lessonId, id} = this.props.match.params
        testCategories = toJS(testCategories)

        const { t } = this.props;

        if(!testGroupAccess){
            return <div className="exam">
                <div className="exam__inner">
                <Popup open={true}>
                <div className="modal">
                    <a className="close" onClick={()=>{
                        this.setState({open:false})
                    }}>
                        &times;
                    </a>

                    <h2>{t('exam.user')}</h2>
                    <p>{t('exam.attemptsLimit')}</p>
                    <button onClick={()=>{
                        this.props.history.push('/test/')
                    }} className="modal__btn">{t('exam.goBack')}</button>
                </div>
            </Popup>
                </div>
            </div>
        }
        if(testCategories===null || testCategories===undefined) return (
            <SpinnerCustom/>
        )
        let generalDuration = 0;
        if(this.state.duration===0) {
            generalDuration = parseInt(testCategories.duration)*60;
        }
        else {
            generalDuration = this.state.duration;
        }

        return (
            <div className="exam">
                <div className="exam__inner">
                    <div className="exam__wrapper">
                        <div className="exam__title"> 
                            <img 
                                src={arrow} alt="" 
                                onClick={() => {
                                    if(!this.state.timeLeft) {
                                        return this.handleBack()
                                    } else {
                                        window.location.href = `/test/${categoryId}/${lessonId}`;
                                        this.props.store.testStore.cleanTest();
                                    }
                                }} 
                            /> {testCategories['name_' + this.props.i18n.language]}</div>
                        {!this.state.timeLeft && (
                            <div className="exam__timer"> <img src={timer} alt="" />
                                <Timer seconds={generalDuration}
                                    finish={this.state.timeLeft}
                                    onFinish={()=>{
                                        this.setState({timeLeft: true})
                                    }}
                                />
                            </div>
                        )}
                        
                    </div>
                    <div className="exam__items" >
                        <LessonTask tasks={testCategories.tests}
                                    lang={LANGUAGES_SMALL[user.language]}
                                    onSubmit={ async (correct, answers)=>{
                                        let id = this.props.match.params['id'];
                                        let catId = this.props.match.params['categoryId'];
                                        console.log('ans', answers);
                                        await this.props.store.testStore.saveResults(id, correct, answers)
                                        localStorage.removeItem(`exam_${id}_end`);
                                        localStorage.removeItem(`exam_${id}_end`);
                                        this.setState({timeLeft: true})
                                    }}
                                    finish={this.state.timeLeft}
                                    onRestart={()=>{
                                        if(this.state.timeLeft) {
                                            this.forceUpdate()
                                        }
                                        this.setState({timeLeft: false})}}
                        />
                    </div>
                </div>

                <Popup open={this.state.modalOpen} closeOnDocumentClick onClose={()=>{
                    this.setState({modalOpen:false})
                }}>
                    <div className="modal">
                        <a className="close" onClick={()=>{
                            if(!this.state.timeLeft) {
                                return this.setState({modalOpen:false})
                            } else {
                                window.location.href = `/test/${categoryId}/${lessonId}`;
                                this.props.store.testStore.cleanTest();
                            }
                        }}>
                            &times;
                        </a>
                        <div className="modalBackModal">
                            <h2>{t('exam.attention')}</h2>
                            <p>{t('exam.testAbortText')}</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button 
                                    onClick={
                                        ()=>{
                                            window.location.href = `/test/${categoryId}/${lessonId}`;
                                            this.props.store.testStore.cleanTest();
                                        }
                                    } 
                                    className="modal__btn">
                                        {t('exam.abort')}
                                </button>
                                <button
                                    style={{ marginLeft: '10px' }} 
                                    onClick={()=>this.setState({modalOpen: false})} 
                                    className="modal__btn">
                                        {t('exam.continue')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }


};


export default withTranslation()(Exam);
