import React from 'react';
import './tests.sass';
import MainCard from '../common/cards/mainCard'
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";

@withTranslation()
@inject('store') @observer
class SundayTests extends React.Component{

    async componentDidMount() {
        await this.props.store.sundayTestStore.getCategories()
    }

    render() {
        let categories = this.props.store.sundatTestStore
        categories = toJS(categories)
        console.log(categories)
        let {t} = this.props;
        if(categories===null) return (
            <SpinnerCustom/>
        )

        return (<></>)

        // return (
        //     <div className="tests">
        //         <div className="tests__inner">
        //             <div className="tests__suptitle">{t('sundayTests.title')}</div>
        //             <div className="tests__items">
        //                 {categories.map((category, idx)=>{
        //                     return <MainCard name={category.name}
        //                                      course={false}
        //                                      count={category.test_groups.length}
        //                                      image={category.image}
        //                                      onClick={()=>{
        //                                        this.props.history.push(`/test/${category.id}/`)
        //                                      }}
        //                                      key={idx}
        //                     />
        //                 })}

        //             </div>
        //         </div>
        //     </div>
        // )
    }


}

export default SundayTests;
