import {observable, computed, action, toJS} from "mobx";
import axios from 'axios'
import {BACKEND_URL} from "../utils/vars";

export default class CourseStore {
    constructor(rootStore) {
        this.rootStore = rootStore
    }
    @observable books = null
    @observable book = null
    @action
    async getBooks() {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/book/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.books = res.data
                resolve(res.data)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                    this.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    async getBook(bookId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/book/${bookId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.book = res.data
                resolve(res.data)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                    this.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
}
