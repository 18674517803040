import React from 'react';
import './lessons.sass';
import arrowLessons from '../images/arrowLessons.svg'
import moduleCard1 from './images/moduleCard1.svg';
import moduleCard2 from './images/moduleCard2.svg';
import moduleCard3 from './images/moduleCard3.svg';
import moduleCard4 from './images/moduleCard4.svg';
import done from '../images/done.svg'

function Lesson() {
    return (
        <div className="lessons__inner">
            <div className="lessons__subtitle"> <img src={arrowLessons} alt="" /> Подготовка к НИШ</div>
            <div className="lessons__items">

                <div className="lessons__item lessons__item1">
                    <div className="lessons__wrapper">
                        <div className="lessons__descr">
                            <div className="lessons__suptitle">12 уроков</div>
                            <div className="lessons__title">Модуль 1</div>
                        </div>
                        <div className="lessons__arrow"> <img src={moduleCard1} alt="" /> </div>
                        {/* ===============мынау галочка шыгу керек. если барин окып битсе================ */}
                        <div className="lessons__done"> <img src={done} alt="" /> </div>
                    </div>
                </div>

                <div className="lessons__item lessons__item1">
                    <div className="lessons__wrapper">
                        <div className="lessons__descr">
                            <div className="lessons__suptitle">12 уроков</div>
                            <div className="lessons__title">Модуль 3</div>
                        </div>
                        <div className="lessons__arrow"> <img src={moduleCard2} alt="" /> </div>
                    </div>
                </div>

                <div className="lessons__item lessons__item1">
                    <div className="lessons__wrapper">
                        <div className="lessons__descr">
                            <div className="lessons__suptitle">12 уроков</div>
                            <div className="lessons__title">Модуль 3</div>
                        </div>
                        <div className="lessons__arrow"> <img src={moduleCard3} alt="" /> </div>
                    </div>
                </div>

                <div className="lessons__item lessons__item1">
                    <div className="lessons__wrapper">
                        <div className="lessons__descr">
                            <div className="lessons__suptitle">12 уроков</div>
                            <div className="lessons__title">Модуль 4</div>
                        </div>
                        <div className="lessons__arrow"> <img src={moduleCard4} alt="" /> </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Lesson;