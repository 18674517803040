import i18n from 'i18next';
import ru from "./ru";
import kz from "./kz";
i18n.init({
    debug: true,

    lng: 'ru',
    resources: {
        'ru':{
            'translation': {
                ...ru
            }
        },
        'kk':{
            'translation': {
               ...kz
            }
        }
    },
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
});

export default i18n
