import React from 'react';
import './authorization.sass'
import img from "./images/authorization-img.svg";
import logomob from "./images/logo-mobile.svg";
import {inject, observer} from "mobx-react";
import validation from "./validation";


@inject('store') @observer
class CreateNewPassword extends React.Component{
    state = {
        tab: 'generate',
        password1: '',
        password2: '',
        error: '',
    }
    async componentDidMount() {
        let token = this.props.match.params['token']
        if (token) {
            try{
                await this.props.store.userStore.checkPasswordToken(token)
            }
            catch (e) {
                this.props.history.push('/')
            }
        }
    }

    handleChange = (key) =>(event) => {
        let value = event.target.value
        const newState = { ...this.state };
        newState[key] = value;
        this.setState(newState);
    }
    handleSubmit = async (e) => {
        e.preventDefault()
        let token = this.props.match.params['token']
        let {password1, password2} = this.state;
        this.setState({error: ''})
        let userData = {
            password: password1,
            confirmPassword: password2,
        }
        const validationError = validation(
            userData,
            'create_new_password'
        )
        console.log(validationError)
        if (validationError !== undefined) {
            if ("password" in validationError) {
                this.setState({error: 'Пароль должен быть минимум 6 букв'})
            }
            if ("confirmPassword" in validationError) {
                this.setState({error: 'Пароли должны совпадать'})
            }
        }
        else {
            this.props.store.userStore.logout()
            try{
                await this.props.store.userStore.setNewPassword(token, password1)
                this.props.history.replace('/')
            }
            catch (e) {

            }
        }
    }
    render() {
        let {error, status, STATUSES} = this.props.store.userStore;
        return (
            <div className="authorization">
                <div className="authorization__inner">
                    <div className="authorization__wrapper">
                        <div className="authorization__img"> <img src={img} alt="" /> </div>
                    </div>
                    <div className="authorization__logo-mobile"> <img src={logomob} alt="" /> </div>
                    {(this.state.error === '' || this.state.error === null) ? null :
                        (
                            <div className="error">
                                {this.state.error}
                            </div>
                        )
                    }
                    <div className="authorization__formWrap">

                         <form className="authorization__form authorization__restore" onSubmit={this.handleSubmit}>
                            <div>
                                <div className="authorization__restore-txt">
                                    <div className="authorization__restore-title">Восстановить пароль</div>
                                    <div className="authorization__restore-subtitle authorization__restore-subtitles">Придумайте новый пароль</div>
                                </div>
                                <input className="authorization__input"
                                       type="password"
                                       placeholder="Пароль"
                                       value={this.state.password1}
                                       onChange={this.handleChange('password1')}
                                />
                                <input className="authorization__input"
                                       type="password"
                                       placeholder="Повторите Пароль"
                                       value={this.state.password2}
                                       onChange={this.handleChange('password2')}
                                />
                                <button
                                    className={"authorization__btn authorization__btn-restore"
                                    +(status===STATUSES.SENDING && " authorization__btn-disabled")}
                                    disabled={status===STATUSES.SENDING}
                                >Восстановить пароль</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }

};

export default CreateNewPassword;
