import React from 'react';
import './course.sass'
import Navbar from '../navbar/navbar.js'
import arrow from '../images/arrow.svg'
import card2 from '../images/card-img2.png';
import card3 from '../images/card-img3.png';
import card1 from '../images/card-img-1.png'
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import MainCard from "../common/cards/mainCard";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";

@withTranslation()
@inject('store') @observer
class Course  extends React.Component{
    async componentDidMount() {
        await this.props.store.courseStore.getCourses()
    }

    render() {
        let {t} = this.props;
        let {courses, STATUSES, status} = this.props.store.courseStore
        courses = toJS(courses)
        if(courses===null || status===STATUSES.LOADING) return (
            <SpinnerCustom/>
        )
        return (
            <div className="course">
                <div className="course__inner">
                    <div className="course__suptitle">{t('courses.title')}</div>

                    <div className="course__items">
                        {courses.map(course=>{
                            return <MainCard
                                name={course.name}
                                count={course.modules.length}
                                image={course.image}
                                onClick={()=>{this.props.history.push(`/course/${course.id}`)}}
                                key={course.id}
                            />
                        })}
                    </div>
                </div>
            </div>
        )
    }


}

export default withRouter(Course);
