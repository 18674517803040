
import {observable, computed, action, toJS} from "mobx";
import axios from 'axios'
import {BACKEND_URL} from "../utils/vars";

export default class TestStore {
    constructor(rootStore) {
        this.rootStore = rootStore
    }
    @observable STATUSES = {
        SENDING: 0,
        ERROR: 1,
        FINISHED: 2,
        DEFAULT: 3,
    }
    @observable categories = null
    @observable tests = null
    @observable testCategories = null
    @observable testCategoriesInfo = null
    @observable testHistories = null
    @observable testHistory = null
    @observable selectedCategory = null
    @observable selectedTestGroup = null
    @observable testsPrice = null

    @observable testGroupAccess = true;
    @action
    async getCategories() {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/test/category/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.categories = res.data

                resolve(true)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    async setSelectedCategory(categoryId) {
        let categories = toJS(this.categories)
        if (categories === null) await this.getCategories();
        categories = toJS(this.categories)
        if(categories || categories.length>0) {
            let categoryIndex = categories.findIndex(category => {
                return category.id === parseInt(categoryId)
            })

            this.selectedCategory = categories[categoryIndex]
        }
    }
    @action getTestGroup(testGroupId){
        let token = localStorage.getItem('token')
        this.testGroupAccess = true;
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/test/group/${testGroupId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.selectedTestGroup = res.data
                this.testGroupAccess = true;
                resolve(true)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                console.log(e.response)
                if(e.response && e.response.status === 403 && e.response.data.detail==="Don't have test group access"){
                    this.testGroupAccess = false;
                }
                resolve(false)
            })
        });
    }
    @action getTests(categoryId){
        let token = localStorage.getItem('token')
        this.testGroupAccess = true;
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/test/group_category/${categoryId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.testCategories = res.data;
                console.log(res.data);

                resolve(true)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                if(e.response && e.response.status === 403 && e.response.data.detail==="Don't have test group access"){
                    console.log('qwewq')
                    this.testGroupAccess = false;
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }

    @action getTestsInfo(categoryId){
        let token = localStorage.getItem('token')
        this.testGroupAccess = true;
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/test/group_category_info/${categoryId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.testCategoriesInfo = res.data;
                resolve(true)
            }).catch((e) => {
                resolve(false)
            })
        });
    }

    @action getTestsHistories(categoryId){
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/test/group_category/${categoryId}/histories/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.testHistories = res.data;
                console.log(res.data);
                resolve(true)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                console.log(e.response)
                resolve(false)
            })
        });
    }

    @action getTestsHistory(historyId){
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/test/results/${historyId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.testHistory = res.data;
                console.log(res.data);
                resolve(true)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                console.log(e.response)
                resolve(false)
            })
        });
    }

    @action saveResults(categoryId, result, answers){
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_URL}/test/results/`,{
                    category: categoryId,
                    correct_count: result,
                    answers: JSON.stringify(answers)
                },
                {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                // this.testCategories = res.data

                resolve(true)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    cleanTest(){
        this.testCategories = [];
    }
    @action
    async getTestsPrice() {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/test/subscription/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.testsPrice = res.data
                resolve(res.data)
            }).catch((e) => {
                if(e.response !==undefined) {
                    if (e.response && e.response.status === 401) {
                        this.logout()
                    }
                    console.log(e.response)

                }
                resolve(false)
            })
        });
    }
    @action
    async addModuleSubscribe(moduleId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_URL}/test/price/add/`, {
                test_group: moduleId
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                resolve(res.data)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    async verifyModuleSubscribe(moduleId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_URL}/test/price/verify/`, {
                test_group: moduleId
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                resolve(res.data)
            }).catch((e) => {
                // if (e.response && e.response.status === 401) {
                //     this.logout()
                // }
                console.log(e.response)
                resolve(false)
            })
        });
    }
}
