import React from 'react'
import './style.scss'

const ModuleSmallCard = ({name, onClick}) => {
    return (
        <span className="module-small-card" onClick={()=>{onClick()}}>
            {name}
        </span>
    )
}
export default ModuleSmallCard;
