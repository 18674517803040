import React, {useState} from 'react';
import './lessons.sass';
import {useTranslation} from "react-i18next";


function LessonCollapse({lessons, onClick, language}) {
    const [active, setActive] = useState(true)
    const [lessonId, setLessonId] = useState(null)
    let {t} = useTranslation()
    return (
        <div className={"lessons-collapse" }>
            <div className="lessons-collapse__wrapper" style={active ? {flex: 1} : {}}>
            <div className="lessons-collapse__title_div" onClick={()=>{
                setActive(!active)
            }}>
                <p>
                    {t('lesson.lessons')}
                </p>
                <img src={require('./images/toggleOpen.svg')}/>
            </div>
            <ul className={"lessons-collapse__items "+(active ? " " : " close")} >
                {lessons && lessons.map((lesson, idx)=>{
                    let name = lesson[`name`]
                    if(language && lesson[`name_${language}`] && lesson[`name_${language}`]!==''){
                        name = lesson[`name_${language}`]
                    }

                    return(
                        <li className="lessons-collapse__item" key={idx} onClick={()=>{if(!lesson.closed) onClick(lesson.id)
                        else setLessonId(lesson.id)}}>{name}
                            {lesson.closed && (<img src={require('./images/lock.svg')}/>)}

                        </li>
                    )
                })}
            </ul>
            </div>
        </div>
    );
};

export default LessonCollapse;
