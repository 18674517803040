import minus from "../images/minus.svg";
import React, {useState} from "react";
import plus from "../images/plus.svg";

function FaqCard({title, description}) {
    const [active, setActive] = useState(false)
    return(
        <div className="faq__item">
            <div className="faq__question"
                 onClick={()=>{setActive(!active)}}>
                {title}
                {(active)
                    ? (<img className="faq__plus" src={minus} alt="" />)
                    :
                    (<img className="faq__plus" src={plus} alt="" />)}
            </div>
                {active ? <div className="faq__answer">{description}</div> : null}
        </div>
    )
}
export default FaqCard
