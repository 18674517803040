import React from 'react';
import './lessons.sass';
import Navbar from '../navbar/navbar.js';
import Lesson from './lesson.js'
import arrowLessons from '../images/arrowLessons.svg';
import lessonscard1 from '../images/lessonsCard1.svg';
import lessonscard2 from '../images/lessonCard2.svg';
import lessonscard3 from '../images/lessonsCard3.svg';
import lessonscard4 from '../images/lessonCard4.svg';
import {inject, observer} from "mobx-react";
import { withRouter } from "react-router-dom";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import ModuleCard from "../common/cards/ModuleCard";
import {LANGUAGES_SMALL} from "../../utils/vars";

@inject('store') @observer
class Modules extends React.Component{
    async componentDidMount() {
        if(!this.props.match.params['id']){
            this.props.history.push('/')
        }
        let id = this.props.match.params['id']
        await this.props.store.courseStore.getModule(id)
    }

    render() {
        let {user} = this.props.store.userStore
        let {selectedModule} = this.props.store.courseStore
        selectedModule = toJS(selectedModule)
        console.log(selectedModule)
        if(selectedModule===null) return (
            <SpinnerCustom/>
        )
        return (
            <div className="lessons">
                {/*<Lesson></Lesson>*/}
                {/* ========= Курсы--> =====fn Lessons-сабактар ================Lesson-ишиндеги модульдар=========================== */}

                { <div className="lessons__inner">
                    <div className="lessons__subtitle"> <img src={arrowLessons} alt="" onClick={()=>{
                        this.props.history.push(`/course/${selectedModule.course}`)
                    }}/>{selectedModule.name}</div>
                    <div className="lessons__description">{selectedModule.description}</div>
                    <div style={{display: 'flex'}}>
                        {selectedModule.categories.map(item=>{
                            return(
                                <ModuleCard image={item.image}
                                            name={
                                                (item[`name_${LANGUAGES_SMALL[user.language]}`])
                                                    ? item[`name_${LANGUAGES_SMALL[user.language]}`]
                                                    : item.name}


                                            onClick={()=>{
                                    this.props.history.push(`/lesson/${item.id}`)
                                }}/>
                            )
                        })}
                    </div>
                </div> }

            </div>
        )
    }


}

export default withRouter(Modules);
