import React from 'react';
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import ModuleSmallCard from "./moduleSmallCard";
import LeaderboardCard from "./leaderboardCard";

@withTranslation()
@inject('store') @observer
class Leaderboard extends React.Component{
    state = {

    }
    async componentDidMount() {
        await this.props.store.leaderboadStore.getModules();
        if(this.props.match.params.id) {
            await this.props.store.leaderboadStore.getLeaderboardList(this.props.match.params.id);
        } else {
            await this.props.store.leaderboadStore.getLeaderboardList();
        }
    }
    changeSelectedModule = (moduleId) => () =>{
        this.props.history.push(`${moduleId}`)
    }
    render() {
        return (
            <div>
                <div>
                    {/* TODO make carousel instead of div */}
                    <ModuleSmallCard name="Все" onClick={this.changeSelectedModule('/leaderboard')}/>
                    {this.props.store.leaderboadStore.modules.map((module)=>{
                        return <ModuleSmallCard name={module.module_full_name} key={module.id}
                                                onClick={this.changeSelectedModule(module.id)}/>
                    })}

                </div>
                <div style={{padding: '10px 15px'}}>
                    {this.props.store.leaderboadStore.leadersList.map((leader, index)=>{
                        return <LeaderboardCard key={index} place={index+1} name={leader.full_name} balance={leader.full_balance}/>
                    })}

                </div>
            </div>
        )
    }
}
export default Leaderboard;
