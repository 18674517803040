import React, {useState} from 'react';
import './authorization.sass';
import RestorePassword from './restorePassword.js'
import img from './images/authorization-img.svg';
import logo from './images/bg.gif'
import logomob from '../navbar/images/logomob.png'
import {inject, observer} from "mobx-react";
import { withTranslation } from "react-i18next";
import validation from "./validation";
import toast, { Toaster } from 'react-hot-toast';
import { BACKEND_URL } from '../../utils/vars';
import axios from 'axios';
import IMask from 'imask';
import { IMaskInput } from 'react-imask';

@withTranslation()
@inject('store') @observer
class Authorization extends React.Component{
    constructor(props){
        super(props)
        this.phoneInputRef = React.createRef()
        this.state = {
            route: 'register',
            type: 'password',
            email: '',
            password1: '',
            password2: '',
            rememberMe: false,
            name: '',
            phone: '',
            grade: '',
            error: '',
            language: 0
        }

    }
    componentDidMount() {
        const { location: { pathname } } = this.props;
        const splittedPathName = pathname.split('/');
        if (splittedPathName.length > 1) {
            if (splittedPathName[1] == 'verify' && splittedPathName[2]) {
                const verifyToken = splittedPathName[2];
                axios.get(`${BACKEND_URL}/auth/verify/${verifyToken}/`)
                    .then(r => {
                        toast.success('Email успешно подтвержден, теперь вы можете войти под своей учетной записью!');
                        this.setState({
                            route: 'login'
                        });
                    })
                    .catch(e => console.log(e, 'error'))
            }
        }
        let token = !!localStorage.getItem('token')
        if(token){
            this.props.history.replace('/')
        }

        // if(this.phoneInputRef.current && this.phoneInputRef.current.id === 'phoneInput') {
        //     let mask = IMask(this.phoneInputRef.current, {
        //         mask: '+7 (000) 000-00-00'
        //     });
        // }
    }

    handleChange = (key) =>(event) => {
        let value = event.target.value
        const newState = { ...this.state };
        newState[key] = value;
        this.setState(newState);
    }
    handleSubmitLogin = async (event) => {
        if(event) event.preventDefault();
        const { email, password1 } = this.state;
        this.setState({ error: '' })

        if (email, password1) {
            await this.props.store.userStore.logout()
            let login = await this.props.store.userStore.login(email, password1)
            console.log(login)
            if (login) {
                window.location.href = '/'
            }
            else {
                this.setState({ error: this.props.t('auth.errors.login') })
            }
        }


    }
    handleSubmit = async (event) => {
        console.log('asdjklas')
        const { email, password1, password2, name, phone, language, grade } = this.state;
        this.setState({ error: '' })
        const { t, i18n } = this.props
        console.log(this.state)
        event.preventDefault();
        if (language===0){
            this.setState({error: 'Выберите язык'})
        }
        else {
            let userData = {
                email: email,
                password: password1,
                confirmPassword: password2,
                full_name: name,
                phone: phone,
                language: language,
                grade: grade
            }
            const validationError = validation(
                userData
            )
            console.log(validationError)
            if (validationError !== undefined) {
                if ("email" in validationError) {
                    this.setState({error: t('auth.errors.email')})
                } else if ("grade" in validationError) {
                    this.setState({error: t('auth.errors.grade')})
                } else if ("password" in validationError) {
                    this.setState({error: t('auth.errors.password')})
                } else if ("confirmPassword" in validationError) {
                    this.setState({error: t('auth.errors.confirmPassword')})
                } else if ("full_name" in validationError) {
                    this.setState({error: t('auth.errors.first_name')})
                } else if ("phone" in validationError) {
                    this.setState({error: t('auth.errors.phone')})
                }
            } else {

                try{
                    await this.props.store.userStore.logout()
                    await this.props.store.userStore.register(userData);

                    if (email && password1) {
                        await this.props.store.userStore.logout()
                        let login = await this.props.store.userStore.login(email, password1)
                        console.log(login)
                        if (login) {
                            window.location.href = '/'
                        }
                        else {
                            this.setState({ error: this.props.t('auth.errors.login') })
                        }
                    }
                    // setTimeout(()=>window.location.href = '/', 500)
                    // toast.success('Вы успешно зарегистрировались, перейдите на почту для подтверждения аккаунта');
                    this.setState({
                        email: '',
                        password1: '',
                        password2: '',
                        name: '',
                        phone: '',
                        grade: '',
                        error: '',
                        language: 0
                    })


                }
                catch (e) {
                    console.log(e)
                     this.setState({error: e})
                }

            }
        }
    }
    setRoute(route){
        this.setState({route:route, error: ''})
    }
    render() {
        const {t} = this.props;
        const {STATUSES, status} = this.props.store.userStore;
        let {route} = this.state;
        return (
            <div className="authorization">
                <div className="authorization__inner">
                    <div className="authorization__wrapper">
                        {/*<div className="authorization__logo"> <img src={logo} alt="" /> </div>*/}
                        <div className="authorization__img"> <img src={img} alt="" /> </div>
                    </div>
                    <div className="authorization__logo-mobile"> <img src={logomob} alt="" /> </div>

                    <div className="authorization__formWrap">
                        <form className="authorization__form" onSubmit={(route==='login')
                            ? this.handleSubmitLogin : this.handleSubmit
                        }>
                            <div>
                                <div className="authorization__txt">
                                    <div className={`authorization__login ${route==='login' ? 'authorization__active' : ''}`}
                                    onClick={()=>{this.setRoute('login')}}>{t('login')}</div>
                                    <div className={`authorization__registration ${route==='register' ? 'authorization__active' : ''}`}
                                         onClick={()=>{this.setRoute('register')}}>{t('register')}</div>
                                </div>

                                {(this.state.error === '' || this.state.error === null) ? null :
                                    (
                                        <div className="error">
                                            {this.state.error}
                                        </div>
                                    )
                                }
                            {/* ===================================Войти============================================ */}
                            {
                                (route==='login') ?
                                    (
                                        <>
                                            <input className="authorization__input" type="email"
                                                   placeholder="Эл.Адрес"
                                                   value={this.state.email}
                                                   onChange={this.handleChange('email')} />
                                            <input className="authorization__input"
                                                   type="password"
                                                   placeholder={t('password')}
                                                   value={this.state.password1}
                                                   onChange={this.handleChange('password1')}
                                            />
                                            <input className="authorization__checkbox" type="checkbox" checked={this.state.rememberMe} onChange={this.handleChange('rememberMe')} />
                                            <label>Запомнить пароль</label>
                                            <button className={"authorization__btn authorization__btn-log "
                                            +(status===STATUSES.SENDING && " authorization__btn-disabled")
                                            } disabled={status===STATUSES.SENDING} type="submit">Войти</button>
                                            <div className="authorization__forgot">
                                                <button onClick={()=>{
                                                    this.props.history.push('/restore')
                                                }} className="authorization__forgot-btn">Забыли пароль</button>
                                            </div>
                                        </>
                                    )
                                    :
                                    (

                                        <>

                                            <input className="authorization__input" type="text"
                                                   placeholder="ФИО"
                                                   value={this.state.name}
                                                   onChange={this.handleChange('name')}
                                            />
                                            <IMaskInput
                                                mask={'+7 (000) 000-00-00'}
                                                radix="."
                                                className='authorization__input'
                                                unmask={true}
                                                ref={this.phoneInputRef}
                                                // inputRef={inputRef}  // access to nested input
                                                onAccept={
                                                    (value, mask) => {
                                                        const newState = { ...this.state };
                                                        newState['phone'] = mask.value;
                                                        this.setState(newState);
                                                    }
                                                }
                                                placeholder='Телефон'
                                            />
                                            <input className="authorization__input"
                                                   type="number"
                                                   placeholder="Класс"
                                                   value={this.state.grade}
                                                   onChange={this.handleChange('grade')}
                                            />
                                            <select className="authorization__input authorization__select"
                                                    defaultValue="0"
                                                    name="Language"
                                                    id=""
                                                    onChange={this.handleChange('language')}
                                            >
                                                <option value="0" disabled>Выбор языка</option>
                                                <option value="1">Казахский</option>
                                                <option value="2">Русский</option>
                                            </select>
                                            <input className="authorization__input"
                                                   type="email"
                                                   placeholder="Эл.Адрес"
                                                   value={this.state.email}
                                                   onChange={this.handleChange('email')}
                                            />
                                            <input className="authorization__input"
                                                   type="password"
                                                   placeholder="Пароль"
                                                   value={this.state.password1}
                                                   onChange={this.handleChange('password1')}
                                            />
                                            <input
                                                className="authorization__input"
                                                type="password"
                                                placeholder="Повторите Пароль"
                                                value={this.state.password2}
                                                onChange={this.handleChange('password2')}
                                            />
                                            <button
                                                className={"authorization__btn authorization__btn-register "
                                                +(status===STATUSES.SENDING && " authorization__btn-disabled")}
                                                disabled={status===STATUSES.SENDING}
                                                type="submit">Зарегистрироваться</button>
                                       </>
                                    )
                            }
                                </div>
                        </form>

                        {/* ==============================Восстановить пароль====================== */}

                        {/*<RestorePassword></RestorePassword>*/}
                    </div>
                </div>
                <Toaster 
                    toastOptions={{
                        success: {
                            duration: 5000
                        }
                    }}
                />
            </div>
        );
    }


};

export default Authorization;
