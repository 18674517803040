import React from 'react';
import './Home.sass'
import {inject, observer} from "mobx-react";
import MainCard from "../common/cards/mainCard";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";

@withTranslation()
class Course  extends React.Component{
    render() {
        let {t} = this.props;

        return (
            <div className="home">
                <div className="home__inner">
                    <div className="home__suptitle">{t('nav.home')}</div>

                    <div className="home__items">
                        <MainCard
                            name={t('nav.course')}
                            image={''}
                            onClick={()=>{this.props.history.push(`/courses`)}}
                        />
                        <MainCard
                            name={t('nav.test')}
                            image={''}
                            onClick={()=>{this.props.history.push(`/test`)}}
                        />
                    </div>
                </div>
            </div>
        )
    }


}

export default withRouter(Course);
