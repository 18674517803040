import validate from "validate.js";

const constraints = {
    email:{
        email: true,
    },
    password: {
        presence: true,
        length: {
            minimum: 6,
            message: "Пароль должен быть минимум 6 букв"
        }
    },
    confirmPassword: {
        equality: "password",
    },
    grade: {
        presence: true,
        format: {
            pattern: "[0-9]{1,2}",
        }
    },
    phone: {
        length: {
            minimum: 11,
            message: function () {
                return "Укажите правильно номер телефона"
            }
        }
    },
    full_name: {
        presence: true,
        length: {
            minimum: 2,
            tooShort: "Введите полное ФИО",
            tokenizer: function(value) {
                return value.split(/\s+/g);
            }
        }
    },
    language: {
        presence: true,

    }
};

const constraintsSettings = {
    email:{
        email: true,
        presence: false,
    },
    password: {
        presence: false,
        length: {
            minimum: 6,
            message: "Пароль должен быть минимум 6 букв"
        }
    },
    confirmPassword: {
        equality: "password",
        presence: false,
    },
    phone: {
        presence: false,
        length: {
            minimum: 11,
            message: function () {
                return "Укажите правильно номер телефона"
            }
        }
    },
    first_name: {
        presence: false,
    }

};

const constraintsCretePassword = {
    password: {
        presence: true,
        length: {
            minimum: 6,
            message: "Пароль должен быть минимум 6 букв"
        }
    },
    confirmPassword: {
        equality: "password",
    },

};


export default function validation(userData, tab=null){
    if(tab==='settings') return validate(userData, constraintsSettings)
    if(tab==='create_new_password') return validate(userData, constraintsCretePassword)
    return validate(userData, constraints);
}
