import React from 'react';
import './navbar.sass';
import './topbar.scss'
import logo from './images/logo-white.png'
import userIcon from './images/user.svg'
import toggleIcon from './images/toggle.svg'
import logoutIcon from './images/log-out.svg'
import menuIcon from './images/menu.svg'
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import {Link, withRouter} from "react-router-dom";
import Burger from "./Burger";
import logomob from './images/logomob.png'
import img from "../authorization/images/authorization-img.svg";
import {withTranslation} from "react-i18next";

@withTranslation()
@inject('store') @observer
class TopBar  extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            toggle: false,
            active: false,
        }
        this.wrapperRef = React.createRef();
        this.cardRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.props.store.userStore.getMe()
        document.addEventListener('mousedown', this.handleClickOutside);
    }


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if(this.cardRef && this.cardRef.current && this.cardRef.current.contains(event.target)){
            this.setState({toggle: true})
        }
        else if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({toggle: false})
        }
    }
    render() {
        let {active, onClickBurger} = this.props;
        let {user} = this.props.store.userStore
        user = toJS(user)
        let {toggle} = this.state;
        let {t} = this.props;
        return (
            <div className={"topbar "+(active && "topbar-active")}>
                <div className="topbar__menu">
                    <Burger isActive={active}
                            onClick={()=>{ onClickBurger()}}
                    />
                </div>
                <div>
                </div>
                <div className="topbar__logo">
                    {active ? <img src={logo} /> : <img src={logomob} alt="" /> }
                </div>
                <div className="topbar__user" style={(active) ?  {display: 'none'} : {}} ref={this.wrapperRef} onClick={()=>{
                    this.setState({toggle: !toggle})
                }}>
                    <img className="topbar__user_icon" src={userIcon} />
                    <p className="topbar__user_email">{toJS(user) && toJS(user.email)}</p>
                    <img className="topbar__user_toggle" src={toggleIcon} />
                </div>
                {toggle && (
                    <div className="topbar__toggle-card" ref={this.cardRef}>
                        <Link to={'/settings'} className="topbar__toggle-card__item" onClick={()=>{
                            this.setState({toggle: false})
                        }}>
                            <img src={userIcon} width="24px"/>
                            {t('profile')}
                        </Link>
                        <div className="topbar__toggle-card__item" onClick={()=>{
                            this.setState({toggle: false})
                            this.props.store.userStore.logout()
                            window.location.href='/'
                        }}>
                            <img src={logoutIcon} width="24px"/>
                            {t('logout')}
                        </div>
                    </div>
                )}

            </div>
        );
    }


};

export default withRouter(TopBar);
