import React from 'react';
import './personalAcc.sass';
import './authorization.sass'
import Navbar from '../navbar/navbar.js'
import {inject, observer} from "mobx-react";
import {observe, toJS} from "mobx";
import {LANGUAGES} from "../../utils/vars";
import './bootstrap-grid.min.css'
import './bootstrap-reboot.min.css'
import {withTranslation} from "react-i18next";
import { IMaskInput } from 'react-imask';

@withTranslation()
@inject('store') @observer
class Setting extends React.Component{
    constructor(props){
        super(props)
        this.phoneInputRef = React.createRef()
        this.state = {
            edit: false,
            city: null,
            email: null,
            full_name: null,
            grade: null,
            parent_full_name: null,
            phone: null,
            language: 2,
            password: null,
            error: null
        }
    }
    async componentDidMount() {
        const user = await this.props.store.userStore.getMe()
        let {city, email, full_name, grade, parent_full_name, phone, language} = user
        this.setState({city: city, email: email, full_name:full_name, grade:grade, parent_full_name:parent_full_name,
        phone:phone, language: language})

    }
    handleChange = (key) =>(event) => {
        let value = event.target.value
        const newState = { ...this.state };
        newState[key] = value;
        this.setState(newState);
    }
    async handleSubmit() {
        let dataToPush = {}
        if (this.state.password && this.state.password.length <= 6) {
            this.setState({error: 'Пароль должен состоять из 6 символов'})
        } else {
            for (let state in this.state) {
                if (state && this.state[state] && state !== 'error' && state !== 'edit') {
                    dataToPush[state] = this.state[state]
                }
            }
            try{
                await this.props.store.userStore.updateUser(dataToPush)
                this.handleCancel()
            }
            catch (e) {

            }
        }
    }
    handleCancel(){
        let {user} = this.props.store.userStore;
        user = toJS(user)
        let {city, email, full_name, grade, parent_full_name, phone, language} = user;
        this.setState({city: city, email: email, full_name:full_name, grade:grade, parent_full_name:parent_full_name,
            phone:phone, language: language, edit: false})

    }
    render() {
        let {city, email, full_name, grade, parent_full_name, phone, language, password} = this.state;
        let {t} = this.props;
        let grades = [];
        for (let i = 1; i < 11; i++) {
            grades.push(i);
        }
        return (
            <div className="personal-acc">
                <div className="personal-acc__inner container-fluid">
                    <div className="personal-acc__wrap">
                        <div className="personal-acc__descr">{t('settings.title')}</div>
                        {/* ==================mobile-адаптивта шыгады================== */}
                        {/* ==================при клике изменить должен поменять на черный цвет=======hover койдым=============== */}
                        <div className="personal-acc__change desktop" onClick={()=>{this.setState({edit:true})}}>Изменить</div>
                        <div className="personal-acc__change mobile" onClick={()=>{this.setState({edit:true})}}>Изм.</div>
                    </div>

                    {/* ===================================Личные данные============================ */}


                    {/* ======================================Изменить ты басканда шыгады=============================== */}
                    {
                        this.state.edit ? (
                            <div className="personal-acc__wrapper">
                                {(this.state.error === '' || this.state.error === null) ? null :
                                    (
                                        <div className="error">
                                            {this.state.error}
                                        </div>
                                    )
                                }
                                <div className="personal-acc__items">
                                    <div className="personal-acc__item col-md-3">
                                        <div className="personal-acc__suptitle">{t('settings.parent_name')}:</div>
                                        <input className="personal-acc__input"
                                               type="text"
                                               placeholder=""
                                               value={parent_full_name}
                                               onChange={this.handleChange('parent_full_name')}
                                        />
                                    </div>
                                    <div className="personal-acc__item col-md-3 offset-md-1">
                                        <div className="personal-acc__suptitle">{t('settings.name')}:</div>
                                        <input className="personal-acc__input"
                                               type="text"
                                               placeholder=""
                                               value={full_name}
                                               onChange={this.handleChange('full_name')}
                                        />
                                    </div>
                                </div>

                                <div className="personal-acc__items">
                                    <div className="personal-acc__item col-md-3">
                                        <div className="personal-acc__suptitle">{t('settings.phone')}:</div>
                                        {/* <input className="personal-acc__input"
                                               type="text"
                                               placeholder=""
                                               value={phone}
                                               onChange={this.handleChange('phone')}
                                        /> */}
                                        <IMaskInput
                                            mask={'+7 (000) 000-00-00'}
                                            radix="."
                                            className='authorization__input'
                                            unmask={true}
                                            defaultValue={phone}
                                            ref={this.phoneInputRef}
                                            // inputRef={inputRef}  // access to nested input
                                            onAccept={
                                                (value, mask) => {
                                                    const newState = { ...this.state };
                                                    newState['phone'] = mask.value;
                                                    this.setState(newState);
                                                }
                                            }
                                            placeholder={t('settings.phone')}
                                        />
                                    </div>
                                    <div className="personal-acc__item col-md-3 offset-md-1">
                                        <div className="personal-acc__suptitle">{t('settings.email')}:</div>
                                        <div className="personal-acc__title">{email}</div>
                                    </div>
                                </div>

                                <div className="personal-acc__items">
                                    <div className="personal-acc__item col-md-3">
                                        <div className="personal-acc__suptitle">{t('settings.grade')}:</div>
                                        <select className="personal-acc__input"
                                                value={LANGUAGES[grade]}
                                                name="grade"
                                                onChange={this.handleChange('grade')}
                                                id="">
                                            {grades.map((g)=>{
                                                return (
                                                    <option value={g} selected={g===grade} >{g} класс</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="personal-acc__item col-md-3 offset-md-1">
                                        <div className="personal-acc__suptitle">{t('settings.language')}:</div>
                                        <select className="personal-acc__input"
                                                name="Language"
                                                onChange={this.handleChange('language')}
                                                id="">
                                            {Object.keys(LANGUAGES).map((lang)=>{
                                                return (
                                                    <option value={lang} selected={lang.toString()===language.toString()}>{LANGUAGES[lang]}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="personal-acc__item col-md-3 offset-md-1">
                                        <div className="personal-acc__suptitle">{t('settings.city')}:</div>
                                        <select className="personal-acc__input"
                                                value={city}
                                                onChange={this.handleChange('city')} name="City" id="">
                                            <option value="Алматы">Алматы</option>
                                            <option value="Астана">Астана</option>
                                            <option value="Шымкент">Шымкент</option>
                                            <option value="Актобе">Актобе</option>
                                            <option value="Караганда">Караганда</option>
                                            <option value="Тараз">Тараз</option>
                                            <option value="Усть-Каменогорск">Усть-Каменогорск</option>
                                            <option value="Павлодар">Павлодар</option>
                                            <option value="Атырау">Атырау</option>
                                            <option value="Семей">Семей</option>
                                            <option value="Кызылорда">Кызылорда</option>
                                            <option value="Актау">Актау</option>
                                            <option value="Костанай">Костанай</option>
                                            <option value="Уральск">Уральск</option>
                                            <option value="Туркестан">Туркестан</option>
                                            <option value="Петропавловск">Петропавловск</option>
                                            <option value="Кокшетау">Кокшетау</option>
                                            <option value="Темиртау">Темиртау</option>
                                            <option value="Талдыкорган">Талдыкорган</option>
                                            <option value="Экибастуз">Экибастуз</option>
                                            <option value="Рудный">Рудный</option>
                                            <option value="Жезказган">Жезказган</option>
                                            <option value="Каскелен">Каскелен</option>
                                            <option value="Жанаозен">Жанаозен</option>
                                            <option value="Кентау">Кентау</option>
                                            <option value="Балхаш">Балхаш</option>
                                            <option value="Сатпаев">Сатпаев</option>
                                            <option value="Кульсары">Кульсары</option>
                                            <option value="Талгар">Талгар</option>
                                            <option value="Сарыагаш">Сарыагаш</option>
                                            <option value="Конаев">Конаев</option>
                                            <option value="Косшы">Косшы</option>
                                            <option value="Жаркент">Жаркент</option>
                                            <option value="Арыс">Арыс</option>
                                            <option value="Аксу">Аксу</option>
                                            <option value="Степногорск">Степногорск</option>
                                            <option value="Щучинск">Щучинск</option>
                                            <option value="Шу">Шу</option>
                                            <option value="Жетысай">Жетысай</option>
                                            <option value="Риддер">Риддер</option>
                                            <option value="Аягоз">Аягоз</option>
                                            <option value="Есик">Есик</option>
                                            <option value="Шахтинск">Шахтинск</option>
                                            <option value="Аральск">Аральск</option>
                                            <option value="Аксай">Аксай</option>
                                            <option value="Алтай">Алтай</option>
                                            <option value="Кандыагаш">Кандыагаш</option>
                                            <option value="Житикара">Житикара</option>
                                            <option value="Сарань">Сарань</option>
                                            <option value="Байконур">Байконур</option>
                                            <option value="Ленгер">Ленгер</option>
                                            <option value="Шардара">Шардара</option>
                                            <option value="Лисаковск">Лисаковск</option>
                                            <option value="Атбасар">Атбасар</option>
                                            <option value="Текели">Текели</option>
                                            <option value="Хромтау">Хромтау</option>
                                            <option value="Абай">Абай</option>
                                            <option value="Аркалык">Аркалык</option>
                                            <option value="Тобыл">Тобыл</option>
                                            <option value="Каратау">Каратау</option>
                                            <option value="Шалкар">Шалкар</option>
                                            <option value="Жанатас">Жанатас</option>
                                            <option value="Алга">Алга</option>
                                            <option value="Ушарал">Ушарал</option>
                                            <option value="Уштобе">Уштобе</option>
                                            <option value="Зайсан">Зайсан</option>
                                            <option value="Шемонаиха">Шемонаиха</option>
                                            <option value="Макинск">Макинск</option>
                                            <option value="Сарканд">Сарканд</option>
                                            <option value="Акколь">Акколь</option>
                                            <option value="Тайынша">Тайынша</option>
                                            <option value="Эмба">Эмба</option>
                                            <option value="Ерейментау">Ерейментау</option>
                                            <option value="Есиль">Есиль</option>
                                            <option value="Приозёрск">Приозёрск</option>
                                            <option value="Курчатов">Курчатов</option>
                                            <option value="Каркаралинск">Каркаралинск</option>
                                            <option value="Форт">Форт</option>
                                            <option value="Булаево">Булаево</option>
                                            <option value="Каражал">Каражал</option>
                                            <option value="Сергеевка">Сергеевка</option>
                                            <option value="Казалинск">Казалинск</option>
                                            <option value="Серебрянск">Серебрянск</option>
                                            <option value="Мамлютка">Мамлютка</option>
                                            <option value="Державинск">Державинск</option>
                                            <option value="Шар">Шар</option>
                                            <option value="Степняк">Степняк</option>
                                            <option value="Темир">Темир</option>
                                            <option value="Жем">Жем</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="personal-acc__items">
                                    <div className="personal-acc__item col-md-3">
                                        <div className="personal-acc__suptitle">{t('settings.password')}:</div>
                                        <input className="personal-acc__input"
                                               type="password"
                                               placeholder="*******"
                                               value={password}
                                               onChange={this.handleChange('password')}
                                        />
                                    </div>
                                </div>

                                <div className="personal-acc__buttons">
                                    <button className="personal-acc__button personal-acc__btn1"
                                    onClick={()=>{this.setState(this.handleCancel())}}>{t('settings.cancel')}</button>
                                    <button className="personal-acc__button personal-acc__btn2"
                                    onClick={()=>{this.handleSubmit()}}>{t('settings.save')}</button>
                                </div>
                            </div>

                        )
                            :
                            (
                                <div className="personal-acc__wrapper">
                                    <div className="personal-acc__items">
                                        <div className="personal-acc__item col-md-3">
                                            <div className="personal-acc__suptitle">{t('settings.parent_name')}:</div>
                                            <div className="personal-acc__title">{parent_full_name}</div>
                                        </div>
                                        <div className="personal-acc__item col-md-3 offset-md-1">
                                            <div className="personal-acc__suptitle">{t('settings.name')}:</div>
                                            <div className="personal-acc__title">{full_name}</div>
                                        </div>
                                    </div>

                                    <div className="personal-acc__items">
                                        <div className="personal-acc__item col-md-3">
                                            <div className="personal-acc__suptitle">{t('settings.phone')}:</div>
                                            <div className="personal-acc__title">{phone}</div>
                                        </div>
                                        <div className="personal-acc__item col-md-3 offset-md-1">
                                            <div className="personal-acc__suptitle">{t('settings.email')}:</div>
                                            <div className="personal-acc__title">{email}</div>
                                        </div>
                                    </div>

                                    <div className="personal-acc__items">
                                        <div className="personal-acc__item col-md-3">
                                            <div className="personal-acc__suptitle">{t('settings.grade')}:</div>
                                            <div className="personal-acc__title">{grade}</div>
                                        </div>
                                        <div className="personal-acc__item col-md-3 offset-md-1">
                                            <div className="personal-acc__suptitle">{t('settings.language')}:</div>
                                            <div className="personal-acc__title">{LANGUAGES[language]}</div>
                                        </div>
                                        <div className="personal-acc__item col-md-3 offset-md-1">
                                            <div className="personal-acc__suptitle">{t('settings.city')}:</div>
                                            <div className="personal-acc__title">{city}</div>
                                        </div>
                                    </div>

                                    <div className="personal-acc__items">
                                        <div className="personal-acc__item col-md-3">
                                            <div className="personal-acc__suptitle">{t('settings.password')}:</div>
                                            <div className="personal-acc__title">* * * * * * * *</div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>

            </div >
        )
    }


}

export default Setting;
