import React from 'react';
import './authorization.sass'
import img from "./images/authorization-img.svg";
import logomob from "./images/logo-mobile.svg";
import {inject, observer} from "mobx-react";


@inject('store') @observer
class RestorePassword extends React.Component{
    state = {
        email: '',
        tab: 'generate',
        error: '',
    }
    handleChange = (key) =>(event) => {
        let value = event.target.value
        const newState = { ...this.state };
        newState[key] = value;
        this.setState(newState);
    }
    handleSubmit = async (e) => {
        e.preventDefault()
        this.setState({error: ''})
        try{
            await this.props.store.userStore.generatePassword(this.state.email)
            this.setState({tab: 'sent'})
        }
        catch (e) {
            this.setState({error: e})
        }
    }
    render() {
        let {error, status, STATUSES} = this.props.store.userStore;
        return (
            <div className="authorization">
                <div className="authorization__inner">
                    <div className="authorization__wrapper">
                        {/*<div className="authorization__logo"> <img src={logo} alt="" /> </div>*/}
                        <div className="authorization__img"> <img src={img} alt="" /> </div>
                    </div>
                    <div className="authorization__logo-mobile"> <img src={logomob} alt="" /> </div>
                    {(this.state.error === '' || this.state.error === null) ? null :
                        (
                            <div className="error">
                                {this.state.error}
                            </div>
                        )
                    }
                    <div className="authorization__formWrap">
                        {this.state.tab==='generate' ? (
                            <form className="authorization__form authorization__restore" onSubmit={this.handleSubmit}>
                                <div>
                                    <div className="authorization__restore-txt">
                                        <div className="authorization__restore-title">Восстановить пароль</div>
                                        <div className="authorization__restore-subtitle
                                    authorization__restore-subtitles">Укажите адрес электронной почты</div>
                                    </div>
                                    <input className="authorization__input" type="email" placeholder="Эл.Адрес"
                                           value={this.state.email} onChange={this.handleChange('email')} autoComplete/>
                                    <button
                                        className={"authorization__btn authorization__btn-restore  "
                                        +(status===STATUSES.SENDING && " authorization__btn-disabled")} disabled={status===STATUSES.SENDING}>Далее</button>
                                </div>
                            </form>
                        ) : (
                             <form className="authorization__form authorization__restore">
                                 <div>
                                     <div className="authorization__restore-txt">
                                         <div className="authorization__restore-title">Восстановить пароль</div>
                                         <div className="authorization__restore-subtitle">Письмо было отправлено. Пожалуйста, нажмите на ссылку, когда получите ее. (Если письмо не пришло, не забудьте проверить папку "Спам")</div>
                                     </div>
                                 </div>
                             </form>
                        )}

                    </div>
                </div>
            </div>
        )
    }

    // return (
    //     <div>
    //
    //         {/* ======================================= Восстановить пароль #1 ======================================== */}
    //
    //         <form className="authorization__form authorization__restore">
    //             <div>
    //                 <div className="authorization__restore-txt">
    //                     <div className="authorization__restore-title">Восстановить пароль</div>
    //                     <div className="authorization__restore-subtitle authorization__restore-subtitles">Укажите адрес электронной почты</div>
    //                 </div>
    //                 <input className="authorization__input" type="email" placeholder="Эл.Адрес" />
    //                 <button className="authorization__btn authorization__btn-restore">Далее</button>
    //             </div>
    //         </form>
    //
    //
    //
    //         {/* ======================================= Восстановить пароль #2 ======================================== */}
    //
    //         {/* <form className="authorization__form authorization__restore">
    //             <div>
    //                 <div className="authorization__restore-txt">
    //                     <div className="authorization__restore-title">Восстановить пароль</div>
    //                     <div className="authorization__restore-subtitle">Письмо было отправлено. Пожалуйста, нажмите на ссылку, когда получите ее</div>
    //                 </div>
    //             </div>
    //         </form> */}
    //
    //
    //
    //         {/* ======================================= Восстановить пароль #3 ======================================== */}
    //
    //         {/* <form className="authorization__form authorization__restore">
    //             <div>
    //                 <div className="authorization__restore-txt">
    //                     <div className="authorization__restore-title">Восстановить пароль</div>
    //                     <div className="authorization__restore-subtitle authorization__restore-subtitles">Придумайте новый пароль</div>
    //                 </div>
    //                 <input className="authorization__input" type="password" placeholder="Пароль" />
    //                 <input className="authorization__input" type="password" placeholder="Повторите Пароль" />
    //                 <button className="authorization__btn authorization__btn-restore">Восстановить пароль</button>
    //             </div>
    //         </form> */}
    //     </div>
    // );
};

export default RestorePassword;
