import {observable, computed, action, toJS} from "mobx";
import axios from 'axios'
import {BACKEND_URL} from "../utils/vars";

export default class LeaderboardStore {
    constructor(rootStore) {
        this.rootStore = rootStore
    }
    @observable modules = []
    @observable leadersList = []

    @action
    async getModules() {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/module/mine`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.modules = res.data
                console.log(res)
                resolve(true)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                    this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }

    @action
    async getLeaderboardList(moduleId=null) {
        let token = localStorage.getItem('token')
        let url = `${BACKEND_URL}/leaderboard`;
        if(moduleId) url = `${url}?module=${moduleId}`
        return new Promise((resolve, reject) => {
            axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.leadersList = res.data
                console.log(res)
                resolve(true)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                    this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
}
