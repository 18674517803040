import React, {useState} from 'react';
import './exam.sass';
import Navbar from '../navbar/navbar.js'
import arrow from '../images/arrowLessons.svg';
import timer from '../images/clock.svg'
import MarkdownRender from "../common/MarkdownRenderer";
import LessonTask from "../lesson/LessonTask";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import Timer from "./timer";
import {LANGUAGES_SMALL} from "../../utils/vars";
import Popup from "reactjs-popup";
import {withTranslation} from "react-i18next";


@withTranslation()
@inject('store') @observer
class ExamRedirector extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            answers: [],
            open: false,
        }
    }
    componentDidMount = async () => {
        let id = this.props.match.params['id']
        let categoryId = this.props.match.params['categoryId']
        let lessonId = this.props.match.params['lessonId']
        if (id){
            await this.props.store.testStore.getTestsHistories(id);
            await this.props.store.testStore.getTests(id);
            await this.props.store.testStore.getTestsInfo(id);
            let testHistories = toJS(this.props.store.testStore.testHistories);
            let testCategories = toJS(this.props.store.testStore.testCategories)
            if(!testHistories || testHistories.length<=0){
                this.props.history.push(`/test/${categoryId}/${lessonId}/exam/${id}/`)
            }
        }
    };

    render() {
        let {user} = this.props.store.userStore;
        let {testCategories, STATUSES, status, testGroupAccess, testCategoriesInfo} = this.props.store.testStore
        let {categoryId,lessonId, id} = this.props.match.params
        testCategories = toJS(testCategories)
        let testHistories = toJS(this.props.store.testStore.testHistories)
        if(!testHistories) return (
            <SpinnerCustom/>
        )
        const {t} = this.props
        return (
            <div className="exam">
                <div className="exam__inner">
                    <div className="exam__wrapper">
                        <div className="exam__title"> <img src={arrow} alt="" onClick={()=>{
                            window.location.href = `/test/${categoryId}/${lessonId}`;
                            this.props.store.testStore.cleanTest();
                        }} /> {testCategoriesInfo && testCategoriesInfo.name}</div>
                    </div>
                   <div>

                      <h2 style={{marginBottom: 15}}>{t('tests.result')}</h2>
                       {
                           testHistories.map((history)=>{
                               return <div className="exam__btn" style={{justifyContent: 'flex-start', marginBottom: 10}}>
                                   <button style={{padding: '10px', borderRadius: 10, textAlign: 'start'}} onClick={()=>{
                                       this.props.history.push(`/test/${categoryId}/${lessonId}/exam-history/${id}/${history.id}/`)
                                   }
                                   }>Дата сдачи: {history.date}
                                       <p>Результат: {history.correct_count} из {history.tests_count}</p></button></div>
                           })
                       }
                       {testCategoriesInfo && ((testCategoriesInfo.bugs_work_video && this.props.i18n.language == 'ru') || (testCategoriesInfo.bugs_work_video_kz && this.props.i18n.language == 'kk')) && (
                            <div className="exam__btn" style={{justifyContent: 'flex-start', marginBottom: 10}}>
                                <button style={{padding: '10px', borderRadius: 10, textAlign: 'start', backgroundColor: '#ff0000'}} onClick={()=>{
                                    return window.open(this.props.i18n.language == 'ru' ? testCategoriesInfo.bugs_work_video : testCategoriesInfo.bugs_work_video_kz)
                                }
                                }>{t('tests.bug_work_video')}</button>
                            </div>
                       )}
                       <div className="exam__btn"  style={{justifyContent: 'flex-start', marginBottom: 10}}
                            onClick={()=>{
                                this.props.history.push(`/test/${categoryId}/${lessonId}/exam/${id}/`)
                            }
                            }
                       ><button style={{padding: '10px', marginTop: 20}}>Сдать экзамен</button></div>
                   </div>
                </div>

            </div>
        );
    }


};


export default ExamRedirector;
