import React from 'react';
import './subscript.sass';
import Navbar from '../navbar/navbar.js';
import check from '../images/check.svg'
import tenge from '../images/cost.svg'
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import ModuleSubscribeCard from "./ModuleSubsribeCard";
import course from "../course/course";
import TestSubscribeCard from "./TestSubscribeCard";
import CryptoJs from "crypto-js";
import arrow from "../images/arrowLessons.svg";
import MarkdownRender from "../common/MarkdownRenderer";
import QRImage from "./images/qr.png";
import Popup from "reactjs-popup";
import {withTranslation} from "react-i18next";

@withTranslation()
@inject('store') @observer
class Tests extends React.Component {
    state={
        selectedCourse: null,
        open: false,
    }
    async componentDidMount() {
        try {
            if ('order_id' in this.props.match.params){
                let order_id = this.props.match.params['order_id']
                await this.props.store.testStore.verifyModuleSubscribe(order_id)
                this.props.history.replace('/subscribe/tests')
            }
            let testsPrice = await this.props.store.testStore.getTestsPrice()
            if(testsPrice){
                this.setState({selectedCourse: 0})
            }
        } catch (e) {

        }
    }
    async subscribe(id, price,  name){
        try {
            const subscribe =
                await this.props.store.testStore.addModuleSubscribe(id)
            const salt = Math.random().toString(36).substring(7);
            // console.log('id', subscribe.id)
            let datas = {
                pg_merchant_id: 515533,
                pg_order_id: subscribe.id,
                pg_amount: price,
                pg_description: `Покупка на Bolashak School ${name}`,
                pg_salt: salt,
                pg_testing_mode: 0,
                pg_result_url: `http://178.170.221.165:8000/api/test/price/verify/`,
                pg_success_url: `http://localhost:3000/subscribe/tests/${subscribe.id}`
            }
            let hashString = '';
            let urlString = "https://api.paybox.money/payment.php?"
            const sortObject = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})
            datas = sortObject(datas)
            for (let data in datas) {
                hashString += datas[data] + ';'
                urlString += `${data}=${datas[data]}&`
            }
            hashString = 'payment.php;' + hashString + 'WSQB7H64BLpBORzX';
            hashString = CryptoJs.MD5(hashString).toString()
            urlString += `pg_sig=${hashString}`
            window.open(urlString, "_self")
        }
        catch (e) {

        }
    }
    render() {
        let {selectedCourse} = this.state;
        let {testsPrice} = this.props.store.testStore;
        testsPrice = toJS(testsPrice)
        let testGroups = null
        console.log(selectedCourse)
        if (selectedCourse!==null && testsPrice[selectedCourse]){
            testGroups = testsPrice
        }
        const {t} = this.props
        return (
            <div className="subscript">
                <div className="subscript__inner">
                    <div className="subscript__suptitle"> <img src={arrow} alt="" onClick={()=>{
                        this.props.history.push('/subscribe')
                    }} /> Тесты </div>
                    {/*<div className="subscript__directions">*/}
                    {/*    {testsPrice && testsPrice.map((course, index)=>{*/}
                    {/*        return (*/}
                    {/*            <button className={"subscript__direction" +*/}
                    {/*            ((selectedCourse===index) ? " subscript__direction-click" : "")} onClick={()=>{*/}
                    {/*                this.setState({selectedCourse: index})*/}
                    {/*            }}>*/}
                    {/*                {course.name}*/}
                    {/*            </button>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</div>*/}
                    <div className="subscript__items flex flex-wrap">
                        {testGroups && testGroups.length>0 &&
                            testGroups.map((testGroup, index)=>
                                <div className="col-md-6 pt-3 pb-3 pl-2 pl-2">
                                    <div className="card " >
                                        <div className="card-body">
                                            <h3 className="card-title">{testGroup.name} <label className="card-price-div"><label className="card-price">{testGroup.price} ₸</label> <s className="card-old-price">{testGroup.old_price} ₸</s></label></h3>
                                            <MarkdownRender>{testGroup.description}</MarkdownRender>
                                            <button className="subscript__btn" onClick={()=>{
                                                this.setState({open:true})
                                            }}>Оформить подписку</button>
                                        </div>
                                    </div>
                                </div>
                                )

                        }


                    </div>
                </div>
                <Popup open={this.state.open} closeOnDocumentClick onClose={()=>{
                    this.setState({open:false})
                }} className="col-12 col-md-6" overlayStyle={{overflow: "scroll"}} >
                    <div className="modal">
                        <a className="close" onClick={()=>{
                            this.setState({open:false})
                        }}>
                            &times;
                        </a>
                        <div className="d-flex justify-content-center">
                            <img src={QRImage} className="col-md-6 col-12"/>
                        </div>
                        <h2>{t('exam.user')}</h2>
                        <ul >
                            <li> 1.	{t('kaspi.app')}</li>
                            <li>    2.	{t('kaspi.pays')}</li>
                            <li>    3.	{t('kaspi.qr')}</li>
                            <li>    4.	{t('kaspi.fio')}</li>
                            <li>    5.	{t('kaspi.sum')}</li>
                            <li>    6.	{t('kaspi.final')}</li>
                        </ul>
                        <a href="https://kaspi.kz/pay/_gate?action=service_with_subservice&service_id=3025&subservice_id=17587&region_id=18" className="modal__btn mb-3 hide">Оплатить</a>
                        <a href="https://wtsp.cc/77752977109" className="modal__btn">{t('kaspi.send')}</a>
                    </div>
                </Popup>
            </div>
        )
    }


}

export default Tests;
