import React from 'react';
import './books.sass'
import Navbar from '../navbar/navbar.js'
import card1 from './images/books-card1.png'
import card2 from './images/books-card2.png'
import card3 from './images/books-card3.png'
import tenge from './images/tenge.svg'
import sale from './images/sales.png'
import sales from './images/sales.svg'
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

@withTranslation()
@inject('store') @observer
class Books extends React.Component{

    componentDidMount = async () => {
        await this.props.store.bookStore.getBooks()
    };

    render() {
        let {books} = this.props.store.bookStore
        books = toJS(books)
        let {t} = this.props
        if(books===null || books===undefined) return (
            <SpinnerCustom/>
        )

        return (
            <div className="books">
                <div className="books__inner">
                    <div className="books__suptitle">{t('books.title')}</div>
                    <div className="books__items">
                        {books.map(book=>{
                            return (
                                <div className="books__item books__item1">
                                    <div className="books__wrapper">
                                        {book.sale && <div className="books__sale"> <img src={sales} alt="" />
                                            <h3 className="books__sale_title">-{100-Math.ceil(book.sale_price*100/book.price)} %</h3></div>}

                                        <div className="books__img"> <img src={book.cover} alt="" /> </div>
                                        <div className="books__descr">
                                            <div className="books__title">{book.name}</div>
                                            {book.sale ? (
                                                <div className="books__subtitle"> <span>{book.price}</span> {book.sale_price} <img src={tenge} alt="" /> </div>
                                            ) : (
                                                <div className="books__subtitle"> {book.price} <img src={tenge} alt="" /> </div>
                                            )

                                            }

                                        </div>
                                        <button className="books__btn" onClick={()=>{
                                            this.props.history.push(`/books/${book.id}`)
                                        }}>{t('books.more')}</button>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        )
    }


}

export default withRouter(Books);
