export function removeOutdatedTestData () {
    for (let i in localStorage) {
        if (i.startsWith('exam')) {
            if (i.endsWith('end')) {
                if (new Date() > new Date(localStorage.getItem(i))) {
                    localStorage.removeItem(i);
                    let re = /end/;
                    let start = i.replace(re, 'start');
                    localStorage.removeItem(start);
                }
            }
        }
    }
}