import React from 'react';
import './bookbuy.sass';
import arrow from '../images/arrowLessons.svg';
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import ImageGallaryComponent from "./ImageGallaryComponent";
import {Carousel} from "react-responsive-carousel";
import tenge from "./images/tenge.svg";
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
@inject('store') @observer
class BookDetail extends React.Component{
    async componentDidMount() {
        let id = this.props.match.params['id']
        if (id) {
            await this.props.store.bookStore.getBook(id)
        }
    }

    render() {
        let {book} = this.props.store.bookStore
        book = toJS(book)

        if(book===null || book===undefined) return (
            <SpinnerCustom/>
        )
        return (
            <div className="book-buy">
                <div className="book-buy__inner">
                    <div className="book-buy__title"> <img src={arrow} alt="" onClick={()=>{
                        this.props.history.push('/books')
                    }} /> Книги</div>
                    <div className="book-buy__wrapper">
                        <div className="book-buy__img">
                            <Carousel autoPlay interval="5000" showIndicators={false} className="custom-slider" >

                                {book.images.map(image=>{
                                    return (
                                        <div style={{backgroundColor: 'white'}}>
                                            <img src={image.image} style={{ borderRadius: 10}} />
                                        </div>
                                    )
                                })}

                            </Carousel>
                        </div>
                        <div className="book-buy__descr">
                            <h3 className="book-buy__name">{book.name}</h3>

                            <label className="book-buy__label">Описание:</label>
                            <p className="book-buy__description">{book.description}</p>
                            <label className="book-buy__label">Цена:</label>
                            {true ? (
                                <div className="books__subtitle"> <span>{book.price}</span> {book.sale_price} <img src={tenge} alt="" /> </div>
                            ) : (
                                <div className="books__subtitle"> {book.price} <img src={tenge} alt="" /> </div>
                            )

                            }
                            <button className="books__btn" onClick={()=>{
                                // this.props.history.push(`/books/${book.id}`)
                            }} style={{maxWidth: '290px'}}>Заказать книгу</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default withRouter(BookDetail);
