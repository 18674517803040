import React from 'react';
import './tests.sass';
import MainCard from '../common/cards/mainCard'
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import ModuleCard from "../common/cards/ModuleCard";
import arrowLessons from "../images/arrowLessons.svg";
import { withTranslation } from 'react-i18next';
import { removeOutdatedTestData } from '../../utils/helpers';
import Popup from 'reactjs-popup';
import './modal.scss'


@inject('store') @observer
class Tests extends React.Component{
    state = {
        modalOpen: false,
        currentCategory: null
    }

    async componentDidMount() {
        let id = this.props.match.params['id']
        // let categoryId = this.props.match.params['categoryId']
        if (id){
            await this.props.store.testStore.getTestGroup(id)
        }
        removeOutdatedTestData();
    }

    handleClick (categoryId, id, category) {
        this.setState({
            modalOpen: !this.state.modalOpen,
            currentCategory: category
        })
    }

    render() {
        let {selectedTestGroup, STATUSES, status} = this.props.store.testStore
        let {categoryId, id} = this.props.match.params
        selectedTestGroup = toJS(selectedTestGroup)
        // console.log(selectedTestGroup)
        if(selectedTestGroup===null || selectedTestGroup===undefined) return (
            <SpinnerCustom/>
        )
        const { t } = this.props;

        return (
            <>
                <div className="tests">
                    <div className="tests__inner">
                        <div className="tests__suptitle"><img src={arrowLessons} alt="" onClick={()=>{
                            this.props.history.push(`/test/${categoryId}`)
                        }}/> {t('tests.title')} </div>
                        <div className="tests__group__items">
                            {selectedTestGroup.test_group_categories.map((category, idx)=>{
                                return  <ModuleCard 
                                    key={idx} 
                                    name={category['name_'+this.props.i18n.language]} 
                                    image={category.image} 
                                    onClick={() => this.handleClick(categoryId, id, category)} 
                                />
                            })}
                        </div>
                    </div>
                </div>
                <Popup open={this.state.modalOpen} closeOnDocumentClick onClose={()=>{
                    this.setState({modalOpen:false})
                }}>
                    <div className="modal">
                        <a className="close" onClick={()=>{
                            this.setState({modalOpen:false})
                        }}>
                            &times;
                        </a>
                        
                        {this.state.currentCategory && (
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <h2>{this.state.currentCategory['name_'+this.props.i18n.language]}</h2>
                                <div className='tests_modal'>
                                    <div>
                                        <p>{t('tests.timing')}:</p>
                                        <span>{this.state.currentCategory.duration} минут(ы)</span>
                                    </div>
                                    <div>
                                        <p>{t('tests.questionsCount')}:</p>
                                        <span>{this.state.currentCategory.tests.filter(item => item.type != 'empty').length}</span>
                                    </div>
                                    <div>
                                        <p>{t('tests.attempts')}:</p>
                                        <span>{this.state.currentCategory.allowed_attempts}</span>
                                    </div>
                                </div>
                                <button 
                                    onClick={()=>this.props.history.push(`/test/${categoryId}/${id}/exam-redirect/${this.state.currentCategory.id}`)} 
                                    className="modal__btn">
                                        {this.state.currentCategory.allowed_attempts > 0 ? t('tests.start') : t('tests.result')}
                                </button>
                            </div>  
                        )}
                    </div>
                </Popup>
            </>
        )
    }


}

export default withTranslation()(Tests);
