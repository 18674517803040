import UserStore from "./UserStore";
import {action, observable, toJS} from "mobx";
import CourseStore from "./CourseStore";
import TestStore from "./TestStore";
import BookStore from "./BookStore";
import LeaderboardStore from "./LeaderboardStore";
import SundayTestStore from "./SundayTestStore";
class RootStore {
    constructor() {
        this.userStore = new UserStore(this)
        this.courseStore = new CourseStore(this)
        this.testStore = new TestStore(this)
        this.bookStore = new BookStore(this)
        this.leaderboadStore = new LeaderboardStore(this);
        this.sundayTestStore = new SundayTestStore(this);
    }
    @observable width = 0;
    @observable height = 0;
    @observable language = 'kz';


    @action
    setHeightAndWidth(height, width){
        this.height = height;
        this.width = width;
    }
}
export default new RootStore();
