import React from 'react'
import {useTranslation} from "react-i18next";

export default function MainCard({name, count, image, onClick, course=true}){
    const { t, i18n } = useTranslation();
    return(
        <div className="course__item" onClick={onClick}>
            <img src={(image) ? image : require('../../images/card-bg1.svg')} className="course__img" />
            <div className="course__wrapper">
                <div className="course__descr">
                    {/* {count && count>0 ? (<div className="course__title">{count} {(course) ? t('courses.card') : "тест"}{count<2 ? "" : "а"}</div>) : ""} */}
                    <div className="course__subtitle">{name}</div>
                </div>
            </div>
        </div>
    )
}
