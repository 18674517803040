import {observable, computed, action, toJS} from "mobx";
import axios from 'axios'
import {BACKEND_URL} from "../utils/vars";

export default class CourseStore {
    constructor(rootStore) {
        this.rootStore = rootStore
    }
    @observable STATUSES = {
        SENDING: 0,
        ERROR: 1,
        FINISHED: 2,
        DEFAULT: 3,
    }
    @observable courses = null;
    @observable selectedModule = null;
    @observable selectedCategory = null;
    @observable selectedLesson = null;
    @observable selectedCourse = null;
    @observable coursesPrices = null;
    @observable status = this.STATUSES.DEFAULT;
    @observable error = ''
    @observable openPhone = false

    @action
    async getCourses() {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/course/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.courses = res.data
                console.error(JSON.stringify(res))
                resolve(true)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                    this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    async selectCourse(courseId) {
        let token = localStorage.getItem('token')
        console.error('token '+token)
        if(token){
            return new Promise((resolve, reject) => {
                axios.get(`${BACKEND_URL}/course/${courseId}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((res) => {
                    this.selectedCourse = res.data

                    resolve(true)
                }).catch((e) => {
                    if (e.response && e.response.status === 401) {
                        this.rootStore.userStore.logout()
                    }
                    resolve(false)
                })
            });
        }

    }
    @action
    async getModule(categoryId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/module/${categoryId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.selectedModule = res.data

                resolve(true)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                     this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }

    @computed get hasNextLesson(){
        let category = toJS(this.selectedCategory)
        let lessonId = category['lessons_list'].findIndex((lesson)=> lesson.id===toJS(this.selectedLesson).id)
        let nextLesson = false
        try{
            nextLesson = category['lessons_list'][lessonId+1]
            if('closed' in nextLesson){
                return false
            }
        }
        catch (e) {

        }
        return nextLesson
    }

    @action
    async setHistory(lessonId, mark=null) {
        let token = localStorage.getItem('token')
        let data = {}
        if (mark) data = {mark:mark}
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_URL}/lesson/${lessonId}/history/`, data,{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                resolve(res.data)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                     this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    async getCoursePrice() {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/price/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.coursesPrices = res.data
                resolve(res.data)
            }).catch((e) => {
                if(e.response !==undefined) {
                    if (e.response && e.response.status === 401) {
                         this.rootStore.userStore.logout()
                    }
                    console.log(e.response)

                }
                resolve(false)
            })
        });
    }
    @action
    async getLesson(lessonId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/lesson/${lessonId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.selectedLesson = res.data

                resolve(true)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                     this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    async getCategory(categoryId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(`${BACKEND_URL}/category/${categoryId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                this.selectedCategory = res.data
                resolve(res.data)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                     this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
    @action
    async addModuleSubscribe(moduleId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_URL}/price/add/`, {
                module: moduleId
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                resolve(res.data)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                     this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }

    @action
    async verifyModuleSubscribe(moduleId) {
        let token = localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.post(`${BACKEND_URL}/price/verify/`, {
                module: moduleId
            },{
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) => {
                resolve(res.data)
            }).catch((e) => {
                if (e.response && e.response.status === 401) {
                     this.rootStore.userStore.logout()
                }
                console.log(e.response)
                resolve(false)
            })
        });
    }
}
