import React from 'react'
import './style.scss'


const LeaderboardCard = ({place, name, img, balance}) => {
    return (
        <div className="leaderboard-card">
            <label className="leaderboard-card__place">{place}.</label>
            {img
                ? <img className="leaderboard-card__image" src={img} />
                : <span className="leaderboard-card__image leaderboard-card__image_placeholder"></span>
            }
            <label>{name}</label>
            <label className="leaderboard-card__balance"> {balance} TCoin</label>
        </div>
    )
}

export default LeaderboardCard;
