import React from 'react';
import './tests.sass';
import MainCard from '../common/cards/mainCard'
import {toJS} from "mobx";
import SpinnerCustom from "../common/SpinnerCustom";
import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";

@withTranslation()
@inject('store') @observer
class Tests extends React.Component{

    async componentDidMount() {
        await this.props.store.testStore.getCategories()
    }

    render() {
        let {categories, STATUSES, status} = this.props.store.testStore
        categories = toJS(categories)
        let {t} = this.props;
        if(categories===null) return (
            <SpinnerCustom/>
        )

        return (
            <div className="tests">
                <div className="tests__inner">
                    <div className="tests__suptitle">{t('tests.title')}</div>
                    <div className="tests__items">
                        {categories.map((category)=>{
                            return <MainCard name={category['name_' + this.props.i18n.language]}
                                             course={false}
                                             count={category.test_groups.length}
                                             image={category.image}
                                             onClick={()=>{
                                               this.props.history.push(`/test/${category.id}/`)
                                             }}
                            />
                        })}

                    </div>
                </div>
            </div>
        )
    }


}

export default Tests;
