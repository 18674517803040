import React from 'react';
import ReactMarkdown from 'react-markdown';
import MathJax from 'react-mathjax';
import RemarkMathPlugin from 'remark-math';
const htmlParser = require('react-markdown/plugins/html-parser')
const HtmlToReact = require('html-to-react');
function MarkdownRender(props) {
    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
    const parseHtml = htmlParser({
        isValidNode: node => node.type !== 'script',
        processingInstructions: [ {
            // Custom <h1> processing
            shouldProcessNode: function (node) {

                return node.parent && node.parent.name && node.parent.name === 'span' && node.parent.attribs.class==="math-tex";
            },
            processNode: function (node, children) {
                let data = node.data
                console.log(data.slice(0,2))
                if(data.slice(0,2)==='\\(') data = data.slice(2, data.length)
                if(data.slice(data.length-1, data.length)==='\)') data = data.slice(0, data.length-1)
                return <MathJax.Node inline formula={data} />
            }
        },
            {
                // Anything else
                shouldProcessNode: function (node) {
                    return true;
                },
                processNode: processNodeDefinitions.processDefaultNode
            }]
    })
    return (
        <MathJax.Provider input="tex">
            <ReactMarkdown {...props} escapeHtml={false}  astPlugins={[parseHtml]} />
        </MathJax.Provider>
    );
}

export default MarkdownRender
