import React from "react";
import doneImg from '../../images/done.svg'
export default function CourseCard({name, count, image, onClick, done=false}) {
    return (
        <div className="lessons__item " onClick={onClick}>
            <img src={(image) ? image : require('../../images/card-bg1.svg')} className="lessons__img" />
            <div className="lessons__wrapper">
                <div className="lessons__descr">
                    {count && <div className="lessons__suptitle">{count} модуля</div>}
                    <div className="lessons__title">{name}</div>
                </div>
                {done &&                 <img src={doneImg} className="lessons__done"/>}

            </div>
        </div>
    )
}
