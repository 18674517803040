import React, {useEffect, useState} from 'react';
import '../tests/exam.sass';
import './lessons.sass'
import MarkdownRender from "../common/MarkdownRenderer";
import arrow from "../images/arrowLessons.svg";
import examDoneIcon from "../images/exam-done-icon.svg";
import {useTranslation} from "react-i18next"; 

function ExamAnswer({check, variant, title, onClick, correct, userClicked}) {
    let icon = (<img src={require('./images/unchecked.svg')}/>)
    if(correct===variant && userClicked) icon= (<img src={require('./images/correct.svg')}/>)
    else if(check!==variant) icon = (<img src={require('./images/checked.svg')}/> )
    if(userClicked===variant && correct!==variant) icon= (<img src={require('./images/error.svg')}/> )
    return(
        <label htmlFor="" className={"exam__item-checkbox " + (correct===variant && userClicked ? "correct" : "")}
               onClick={()=>onClick(variant)}
        >
            {icon}

            <span className="exam__item-variant" style={correct===variant ? {color: "#4BCA1E"} : userClicked===variant && correct!==variant ? {color: "#e7000b"} : {}}>{variant}</span>
            <span className={"exam__item-descr " + (correct===variant ? "correct" : userClicked===variant && correct!==variant ? "incorrect" : "")} ><MarkdownRender source={title} /></span>
        </label>
    )
}

export function ExamItem({id, index,title,a,b,c,d, e=null,onClick,empty=false, correct=false, edit=false, userClicked}){
    const [check, setCheck] = useState(null)

    const onCheck = (variant)=>{
        if(!edit) {
            setCheck(variant)
            onClick(id, variant)
        }
    }
    if(userClicked!=null) userClicked=Object.values(userClicked)[0]
    return(
    <div className="exam__item">
        <div className="exam__item-title">
            {!empty && (<span style={{marginRight: 5}}> {index}. </span>)} <div><MarkdownRender source={title} /></div>
        </div>
        {!empty && (
            <div className="exam__item-form">
                <ExamAnswer check={check} variant={'A'} title={a}
                            onClick={onCheck} correct={correct}  userClicked={userClicked}/>
                <ExamAnswer check={check} variant={'B'} title={b}
                            onClick={onCheck} correct={correct} userClicked={userClicked}/>
                <ExamAnswer check={check} variant={'C'} title={c}
                            onClick={onCheck} correct={correct} userClicked={userClicked}/>
                <ExamAnswer check={check} variant={'D'} title={d}
                            onClick={onCheck} correct={correct} userClicked={userClicked}/>
                {e && <ExamAnswer check={check} variant={'E'} title={e}
                            onClick={onCheck} correct={correct} userClicked={userClicked}/>}
            </div>
        )}

    </div>
    )
}



function LessonTaskQuestions({min, tasks, onSubmit, onPass, finish=false, onRestart, lang}) {
    const STATUSES = {
        ON_PROCESS:0,
        FAIL:1,
        PASS:2,
        WATCH_ANSWERS: 3
    }
    const [answers, setAnswer] = useState({})
    const [answersHistory, setAnswerHistory] = useState({})
    const [status, setStatus] = useState(STATUSES.ON_PROCESS)
    let {t} = useTranslation()
    const questionCheck = () => {
        let count = 0
        for (let value of Object.values(answers)) {
            if(value===true){
                count++
            }
        }
        return count
    }

    const submitTask = () =>{
        if (tasks.length) {
            const quests = [];
            tasks.map(item => {
                if (item.type != 'empty') {
                    quests.push(item)
                }
            })
            if(quests.length != Object.keys(answers).length && !finish) {
                alert(t('tests.missed_answers'))
                return false;
            }
        }

        let correct = questionCheck()
        if(onSubmit){
            onSubmit(correct, answersHistory);
        }
        if(correct>=min){
            setStatus(STATUSES.PASS)
        }
        else(
            setStatus(STATUSES.FAIL)
        )
    }

    const renderItem = () => {

        if(status===STATUSES.ON_PROCESS){
            if(finish){
                submitTask()
            }
            let counter = 0;
           return( <>
            {tasks.map((task)=>{
                let {ans_a, ans_b, ans_c, ans_d,ans_e, description} = task
                if (lang){
                    if(task[`ans_a_${lang}`] && task[`ans_a_${lang}`]!==''){
                        ans_a =task[`ans_a_${lang}`]
                    }
                    if(task[`ans_b_${lang}`] && task[`ans_b_${lang}`]!==''){
                        ans_b =task[`ans_b_${lang}`]
                    }
                    if(task[`ans_c_${lang}`] && task[`ans_c_${lang}`]!==''){
                        ans_c =task[`ans_c_${lang}`]
                    }
                    if(task[`ans_d_${lang}`] && task[`ans_d_${lang}`]!==''){
                        ans_d =task[`ans_d_${lang}`]
                    }
                    if(task[`ans_e_${lang}`] && task[`ans_e_${lang}`]!==''){
                        ans_e =task[`ans_e_${lang}`]
                    }
                    if(task[`description_${lang}`] && task[`description_${lang}`]!==''){
                        description =task[`description_${lang}`]
                    }
                }
                if(task.type!=='empty') counter +=1;
                return(
                    <ExamItem
                        title={description}
                        key={task.id}
                        index={counter}
                        empty={task.type==='empty'}
                        a={ans_a}
                        b={ans_b}
                        c={ans_c}
                        d={ans_d}
                        e={ans_e}
                        id={task.id}

                        onClick={(id, value)=>{
                            setAnswer({...answers, [id]: task.correct===value})
                            setAnswerHistory({...answersHistory, [id]: value})
                        }}
                    />
                )
            })}
            <div className="exam__btn" onClick={submitTask}>
                <button>{t('lesson.submit')}</button>
            </div>
                </>
           )
        }
        else if(status===STATUSES.PASS){
            return (<>
                <div className="exam-done">
                    <div className="exam-done__icon"> <img src={examDoneIcon} alt="" /> </div>
                    <div className="exam-done__result">{t('lesson.submit')}: <span>{questionCheck()}</span>
                        {t('lesson.resultFrom')}
                        <span> {tasks.length} </span>!</div>
                    <div className="exam-done__subtitle">{t('lesson.grats')}!</div>
                    <div className="exam-done__btn">
                        <button onClick={()=>{setStatus(STATUSES.WATCH_ANSWERS)}}>{t('lesson.showCorrect')}</button>
                    </div>
                    <div className="exam-done__btn">
                        <button onClick={()=>{onPass()}}>{t('lesson.nextLesson')}</button>
                    </div>
                </div>
                    </>)}
        else if(status===STATUSES.FAIL){
                return (<div className="exam-done">
                    <div className="exam-done__icon"> <img src={examDoneIcon} alt="" /> </div>
                    <div className="exam-done__result">Ваш результат: <span>{questionCheck()}</span>
                        &nbsp;
                        {t('lesson.resultFrom')}
                        <span> {tasks.filter(task=>task.type!=='empty').length} </span>!</div>
                    <div className="exam-done__btn">
                        <button onClick={()=>{setStatus(STATUSES.WATCH_ANSWERS)}}
                        >{t('lesson.showCorrect')}</button>
                    </div>
                </div>)
            }
        else if(status===STATUSES.WATCH_ANSWERS){
            let counter = 0;
            return( <>
                    {tasks.map((task, index)=>{
                        let {ans_a, ans_b, ans_c, ans_d, ans_e, description} = task
                        if (lang){
                            if(task[`ans_a_${lang}`] && task[`ans_a_${lang}`]!==''){
                                ans_a =task[`ans_a_${lang}`]
                            }
                            if(task[`ans_b_${lang}`] && task[`ans_b_${lang}`]!==''){
                                ans_b =task[`ans_b_${lang}`]
                            }
                            if(task[`ans_c_${lang}`] && task[`ans_c_${lang}`]!==''){
                                ans_c =task[`ans_c_${lang}`]
                            }
                            if(task[`ans_d_${lang}`] && task[`ans_d_${lang}`]!==''){
                                ans_d =task[`ans_d_${lang}`]
                            }
                            if(task[`ans_e_${lang}`] && task[`ans_e_${lang}`]!==''){
                                ans_e =task[`ans_e_${lang}`]
                            }
                            if(task[`description_${lang}`] && task[`description_${lang}`]!==''){
                                description =task[`description_${lang}`]
                            }
                        }
                        if(task.type!=='empty') counter +=1;
                        return(
                            <ExamItem
                                title={description}
                                key={index}
                                index={counter}
                                empty={task.type==='empty'}
                                a={ans_a}
                                b={ans_b}
                                c={ans_c}
                                d={ans_d}
                                e={ans_e}
                                id={task.id}
                                edit={true}
                                correct={task.correct}
                                userClicked={answersHistory[task.id]}
                                onClick={(id, value)=>{
                                    setAnswer({...answers, [id]: task.correct===value})
                                }}
                            />
                        )
                        return null;
                    })}
                    {/*<div className="exam__btn">*/}
                    {/*    <button onClick={()=>{*/}
                    {/*        setStatus(STATUSES.ON_PROCESS)*/}
                    {/*        // onRestart()*/}
                    {/*        }}>{t('lesson.retry')}</button>*/}
                    {/*</div>*/}
                </>
            )
        }
    }
    return (
        <>
        {renderItem()}
       </>
    );
};

export default LessonTaskQuestions;
