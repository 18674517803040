import React from 'react';
import './faq.sass';
import Navbar from '../navbar/navbar.js'
import minus from '../images/minus.svg'
import plus from '../images/plus.svg'
import FaqCard from "./FaqCard";

function Faq() {
    return (
        <div className="faq">
            <div className="faq__inner">
                <div className="faq__title">FAQ</div>
                <div className="faq__wrapper">
                    <div className="faq__items">
                        <FaqCard title="Как долго существует ваш учебный центр?" description="Интеллектуальная школа дополнительного образования «Bolashak School» работает в городе Алматы c 2015 года.
"/>
                        <FaqCard title="Проблемы с оплатой можете мне помочь ?" description="Напишите нам на https://wtsp.cc/77752977109"/>
                        <FaqCard title="Можете ли вы предоставить информацию курсов для 4-5 классов?" description="Передите по ссылке https://clck.ru/33XxnS"/>
                    </div>
                    <div className="faq__contacts">
                        <div className="faq__contacts-inner">
                            <div className="faq__contacts-question">Как мы можем помочь Вам?</div>
                            <div className="faq__contacts-descr">Если вы не нашли ответ на интересующий вас вопрос, напишите нам письмо, мы обязательно ответим!</div>
                            <div className="faq__contacts-numbers">
                                <div className="faq__contacts-number">+7 (707) 814-44-46</div>
                                <div className="faq__contacts-number">+7 (707) 814-44-46</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;
