// export const BACKEND_URL = 'http://localhost:8000/api'
// export const BACKEND_URL_HOST = 'http://localhost:8000'
// export const BACKEND_URL = 'http://37.18.30.23:8000/api'
// export const BACKEND_URL = 'http://portal.bolashak-school.kz:8000/api'
export const BACKEND_URL = 'https://portal.bolashak-school.kz/api'
export const LANGUAGES = {
    1: 'Казак',
    2: 'Русский'
    // 3: 'English'
}
export const LANGUAGES_SMALL = {
    1: 'kk',
    2: 'ru'
    // 3: 'en'
}
