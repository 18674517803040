const kz = {
    //LOGIN
    language: 'Қазақша',
    login: 'Кіру',
    register: 'Тіркелу',
    have_no_account: 'Сізде әлі аккаунт жоқ па?',
    forgot_password: 'Құпия сөзді ұмыттыңыз ба?',
    password: 'Құпия сөз',
    exam: {
        attention: 'Назар аударыңыз',
        testAbortText: 'Сіз шынымен сынақты тоқтатқыңыз келе ме? Таймер іске қосылғандықтан, сіз сынақтан толық өтуіңіз керек',
        abort: 'Тоқтату',
        continue: 'Жалғастыру',
        user: 'Құрметті қолданушы',
        attemptsLimit: 'Сынақтағы мүмкіндіктер санынан асып кеттіңіз немесе сынаққа кіру мүмкіндігіңіз жоқ',
        goBack: 'Кері'
    },
    tests: {
        title: 'Тесттер',
        missed_answers: 'Вы ответили не на все вопросы',
        timing: 'Ұзақтығы',
        questionsCount: 'Сұрақтар саны',
        attempts: 'Талпыныс қалды',
        start: 'Тестілеуді бастау',
        result: 'Нәтижені қарау',
        bug_work_video: 'Есептердің видео түсіндірмесі'
    },
    books: {
        title: 'Кітаптар',
        more: 'Толығырақ'
    },
    courses: {
        title: 'Курстар',
        card: 'сабақ'
    },
    subscriptions: {
      title: 'Жазылу'
    },
    lesson: {
        videoLesson: 'Видео сабақ',
        task: 'Тапсырма',
        lessons: ' Cабақтар',
        showCorrect: 'Жауаптарды қарау',
        nextLesson: 'Келесі сабаққа өту',
        retry: 'Қайта тапсырыңыз',
        submit: 'Тапсыру',
        result: 'Сіздің нәтижеңіз',
        resultFrom: 'балдан',
        grats: 'Құттықтаймыз'
    },
    nav: {
        home: 'Басты бет',
        course: 'Курстар',
        test: 'Тесттер',
        book: 'Кітаптар',
        subscription: 'Жазылу',
        faq: 'FAQ',
        sundayTests: 'Воскресные тесты'
    },
    profile: 'Жеке бөлме',
    logout: 'Шығу',
    settings: {
        title: 'Жеке бөлме',
        parent_name: 'Ата-анасының аты',
        name: 'Студенттің аты-жөні',
        phone: 'Телефон',
        grade: 'Класс',
        language: 'Оқу тілі',
        email: 'Эл. адрес',
        city: 'Қала',
        password: 'Пароль',
        cancel: 'Бас тарту',
        save: 'Сақтау',
        change: 'Өңдеу'
    },
    kaspi: {
        app: 'Kaspi.kz мобайл қосымшасын AppStore, Google Play-дан жүктеңіз',
        pays: '«Төлемдер» сервисін ашып, «Bolashak School (г.Алматы, ул.Сатпаева, 22Б)» табыңыз',
        qr: 'Сондай ақ сіз «Kaspi  QR» кодын сканерлей аласыз',
        fio: 'Оқушының аты-жөнін енгізіңіз',
        sum: 'Төленетін соманы енгізіп, «Төлеу» түймесін басыңыз',
        final: 'Төлемнен кейін WhatsApp 87752977109 нөміріне чекті жіберіңіз',
        send: 'Чекті жіберу'
    }
};

export default kz;
